
import { Component, Prop, Vue } from 'vue-property-decorator'

@Component({
  name: 'whatsapp-share-button'
})
export default class WhatsappButton extends Vue {
  @Prop({ default: 'transparent' }) variant: string
  @Prop({ default: 'smb-fb-foerder-app' }) customClass: string
  @Prop() hashtags: string[]
  @Prop({ default: '' }) shareText: string

  get shareUrl (): string {
    return `whatsapp://send?text=${this.shareText} ${window.location}`
  }
}
