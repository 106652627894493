/**
 * Sets a cookie without encoding it
 * @param name
 * @param value
 * @param exdays - max age in days
 */
export const setCookie = (name: string, value: string, exdays = 30, path = '/'): void => {
  const d = new Date()
  d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000)

  const expires = `expires=${d.toUTCString()}`

  document.cookie = `${name}=${value};${expires};path=${path}`
}
