
import { Component, Prop, Watch, Vue } from 'vue-property-decorator'
import axios from 'axios'
import FormModule from '@/components/FormModule.vue'
import SfAlert from '@/components/SfAlert.vue'
import { ISelectGroup, ISelectOption, TGenericObject } from '@/types/base'
import { IDocument, IMappingField } from '@/types/documents'
import { API_URLS } from '@/utils/helpers'

@Component({
  name: 'map-content-step',
  components: {
    FormModule,
    SfAlert
  }
})
export default class MapContentStep extends Vue {
  @Prop() value!: TGenericObject
  @Prop() document!: IDocument
  @Prop({ default: false }) saving!: boolean
  selected: TGenericObject = {}
  individualFieldDescriptions: TGenericObject = {}
  individualFieldValues = ['custom.custom_input', 'custom.custom_textarea']
  availableFields: TGenericObject = {}
  additionalFieldSelectOptions: (ISelectOption | ISelectGroup)[] = []

  @Watch('selected')
  onSelectChange (): void {
    this.$emit('input', this.selected)
  }

  getOptions (selectType: string): (ISelectOption | ISelectGroup)[] {
    const baseOptions = [{ value: null, text: this.$gettext('Please choose') }]
    if (this.availableFields[selectType]) {
      const fields = this.availableFields[selectType]
      return [
        ...baseOptions,
        ...Object.keys(fields).map(key => { return { label: key, options: fields[key] } })
      ]
    }
    return baseOptions
  }

  get textSelectOptions (): (ISelectOption | ISelectGroup)[] {
    return this.getOptions('text')
  }

  get checkboxSelectOptions (): (ISelectOption | ISelectGroup)[] {
    return this.getOptions('checkbox')
  }

  get textFields (): IMappingField[] {
    if (!this.document.fields.length) return []
    return this.document.fields.filter(field => field.type === 'Text')
  }

  get checkboxFields (): IMappingField[] {
    if (!this.document.fields.length) return []
    return this.document.fields.filter(field => field.type === 'Button')
  }

  get allAvailableDocumentFields (): IMappingField[] {
    return [...this.textFields, ...this.checkboxFields]
  }

  get individualFields (): IMappingField[] {
    return this.textFields.filter(field => {
      return (field.name in this.selected && this.individualFieldValues.includes(this.selected[field.name]))
    })
  }

  getFieldsWithSelectValues (): IMappingField[] {
    const tmpFields = this.allAvailableDocumentFields.map(field => ({ ...field }))
    for (const field of tmpFields) {
      if (this.selected[field.name] !== undefined) {
        field.mapping = this.selected[field.name]
      }
      if (this.individualFieldDescriptions[field.name]) {
        field.description = this.individualFieldDescriptions[field.name]
      }
    }
    return tmpFields
  }

  created (): void {
    for (const field of this.allAvailableDocumentFields) {
      this.$set(this.selected, field.name, field.mapping ? field.mapping : null)
      if (this.individualFieldValues.includes(field.mapping)) {
        this.$set(this.individualFieldDescriptions, field.name, field.description ? field.description : '')
      }
    }

    axios.get(API_URLS.DOCUMENTS.AVAILABLE_FIELDS(this.document.slug)).then(response => {
      this.availableFields = response.data
    })
  }
}
