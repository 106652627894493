import { Component, Vue } from 'vue-property-decorator'

@Component({
  name: 'locale-mixin'
})
export default class LocaleMixin extends Vue {
  get locale (): string {
    if (this.$language.current) {
      return this.$language.current.startsWith('de_') || this.$language.current.endsWith('_DE')
        ? 'de'
        : this.$language.current.replace('_', '-')
    }

    return 'en-US'
  }

  toCurrency (val: number | string, options: Intl.NumberFormatOptions = {}): string {
    if (typeof val === 'string') {
      val = parseFloat(val)
    }

    return val.toLocaleString(this.locale, { currency: 'EUR', style: 'currency', ...options })
  }

  toCoins (val: number, factor: number): string {
    const coins = val * factor

    return coins.toLocaleString(this.locale, { useGrouping: true, maximumFractionDigits: 0 })
  }

  centsToCurrency (val: number | string, options: Intl.NumberFormatOptions = {}): string {
    if (typeof val === 'string') val = parseFloat(val)
    return this.toCurrency(val / 100, options)
  }

  toLocalisedDecimal (val: number | string, options: Intl.NumberFormatOptions = {}): string {
    if (typeof val === 'string') {
      val = parseFloat(val)
    }

    return val.toLocaleString(this.locale, {
      style: 'decimal',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
      ...options
    })
  }

  toLocalisedInteger (val: number | string): string {
    if (typeof val === 'string') val = parseFloat(val)
    val = Math.round(val)
    return val.toLocaleString(this.locale)
  }

  formatBoolean (value: boolean): string {
    return value ? this.$gettext('Yes') : this.$gettext('No')
  }

  // Get localised date
  formatDate (dateStr: string): string {
    if (!dateStr) return '-'
    return this.$moment(dateStr).format('LLL')
  }

  formatDateOnlyDay (dateStr: string): string {
    if (!dateStr) return '-'
    return this.$moment(dateStr).format('LL')
  }

  getMonthName (monthNumber: number): string {
    return this.$moment().month(monthNumber).format('MMMM')
  }

  getCurrencySymbol (): string {
    return '€' // TODO figure out how to handle different currencies @future-people suck it
  }
}
