

import { Component, Prop, Vue } from 'vue-property-decorator'
import IconButton from './IconButton.vue'

@Component({
  components: {
    IconButton
  }
})
export default class DeleteButton extends Vue {
  @Prop({ required: false }) href!: string
  @Prop({ default: () => Vue.prototype.$gettext('Delete') }) tooltip!: string
  @Prop({ default: false }) disabled!: boolean
}
