
import { Component, Prop, Mixins, Watch } from 'vue-property-decorator'
import CoinCodeInputArea from './CoinCodeInputArea.vue'
import { ICodeFormatSettings, ICurrencyDisplaySettings, IProjectDetailSettings } from '@/types/cms'
import { IBalance } from '@/types/base'
import { IUserProfile } from '@/types/users'

import LocaleMixin from '@/mixins/LocaleMixin'
import axios from 'axios'
import { API_URLS } from '@/utils/helpers'
import { IExploreProjectDetail } from '@/types/projects'
import { IDonateOnProjectPostData } from '@/types/finances'
import { IExploreOrganizationDetail } from '@/types/organizations'

@Component({
  name: 'support-project-area',
  components: {
    CoinCodeInputArea
  }
})
export default class SupportProjectArea extends Mixins(LocaleMixin) {
  @Prop() project!: IExploreProjectDetail | IExploreOrganizationDetail
  @Prop({ default: null }) projectDetailSettings: IProjectDetailSettings
  @Prop({ default: null }) userBalance: IBalance
  @Prop({ default: null }) userProfile: IUserProfile
  @Prop({ default: false }) isOrganization!: boolean

  code = ''
  saving = false
  redeemValue = 0
  infoMessage: string = null
  warningMessage: string = null

  @Watch('userBalance')
  onUserBalanceChange (): void {
    this.updateRedeemValue()
  }

  @Watch('redeemValue')
  onRedeemValueChange (): void {
    this.redeemValue = this.currencyDisplay.useCurrency ? Number(Number(this.redeemValue).toFixed(2)) : Math.floor(this.redeemValue)
    this.redeemValue = Math.min(this.redeemValue, this.maxRedeemValue)
  }

  get showRedeemArea (): boolean {
    return this.userBalance?.in_currency > 0
  }

  get redeemInputDisabled (): boolean {
    return !(this.userBalance && this.userBalance.in_coins > 0)
  }

  get maxUserBalanceInCurrency (): number {
    return this.userBalance?.in_currency || 0
  }

  get maxUserBalanceInCoins (): number {
    return this.userBalance?.in_coins || 0
  }

  get coinsEnabled (): boolean {
    return this.projectDetailSettings?.enableCoinDonations || false
  }

  get currencyDisplay (): ICurrencyDisplaySettings {
    return this.projectDetailSettings?.currencyDisplay
  }

  get codeFormat (): ICodeFormatSettings {
    return this.projectDetailSettings?.codeFormat
  }

  get distributeTitle (): string {
    return this.$gettextInterpolate(this.$gettext('Distribute %{ displayName }'), { displayName: this.currencyDisplay.coinNamePlural })
  }

  get maxRedeemValue (): number {
    if (!this.userBalance) return 1
    if (this.currencyDisplay.useCurrency) {
      return this.userBalance.in_currency
    } else {
      return this.userBalance.in_coins
    }
  }

  get getStepSize (): number {
    if (this.currencyDisplay.useCurrency) {
      // even when currency step should be lower than coin value
      return 1.0 / this.projectDetailSettings.currencyDisplay.factorOfCurrencyToCoin
    } else {
      return 1
    }
  }

  updateRedeemValue (): void {
    this.redeemValue = this.maxRedeemValue
  }

  donateOnProject (): void {
    this.infoMessage = null
    this.warningMessage = null
    const donationData: IDonateOnProjectPostData = {
      amount: this.redeemValue
    }
    if (this.isOrganization) {
      donationData.promoter_slug = this.project.slug
    } else {
      donationData.project_slug = this.project.slug
    }

    axios.defaults.headers.common['X-CSRFToken'] = this.$cookies.get('csrftoken')
    axios.post(API_URLS.V3.DONATE_ON_PROJECT, donationData).then((res) => {
      this.$emit('donated', res.data)
      this.infoMessage = res.data?.info_message
    }).catch(res => {
      this.warningMessage = res.response.data.error
    })
  }

  created () {
    this.updateRedeemValue()
  }
}
