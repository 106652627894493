
import { Component, Vue, Watch, Prop } from 'vue-property-decorator'
import Chart from 'chart.js'
@Component({
  name: 'circular-progress-bar',
  components: {
    Chart
  }
})
export default class CircularProgressBar extends Vue {
  @Prop({ default: 0 }) needsReachedInPercent: number
  @Prop({ default: 0 }) donationCodeFundingInPercent: number
  @Prop({ default: 0 }) coFundingFundingInPercent: number
  @Prop({ default: 0 }) privateFundingInPercent: number
  @Prop({ default: 0 }) otherFundingInPercent: number
  chart = null

  @Watch('needsReachedInPercent')
  onNeedsReachedInPercentChanged (): void {
    this.initialiseChartData()
  }

  get chartLabels (): string[] {
    const labels = []
    if (this.donationCodeFundingInPercent) labels.push(this.$gettext('Donation codes'))
    if (this.privateFundingInPercent) labels.push(this.$gettext('Private donations'))
    if (this.coFundingFundingInPercent) labels.push(this.$gettext('Co-Funding'))
    if (this.otherFundingInPercent) labels.push(this.$gettext('Direct funding'))
    labels.push('')
    return labels
  }

  get chartData (): number[] {
    const data = [
      this.donationCodeFundingInPercent,
      this.privateFundingInPercent,
      this.coFundingFundingInPercent,
      this.otherFundingInPercent,
      100 - this.needsReachedInPercent
    ]
    return data.filter(val => !!val)
  }

  getBackgroundColourFromElement (className: string): string {
    const el = document.querySelector(className)
    if (!el) return null
    return window.getComputedStyle(el).backgroundColor
  }

  get chartColours (): string[] {
    const colours = []
    const constBaseColor = getComputedStyle(document.body).getPropertyValue('--base-ci')
    if (this.donationCodeFundingInPercent) colours.push(this.getBackgroundColourFromElement('.legend.coins') || constBaseColor || '#E94580')
    if (this.privateFundingInPercent) colours.push(this.getBackgroundColourFromElement('.legend.private') || '#6AB2B3')
    if (this.coFundingFundingInPercent) colours.push(this.getBackgroundColourFromElement('.legend.cofunding') || '#F8E472')
    if (this.otherFundingInPercent) colours.push(this.getBackgroundColourFromElement('.legend.internal') || '#84AF72')
    colours.push('#FFFFFF')
    return colours
  }

  initialiseChartData (): void {
    const ctx = this.$el.querySelector('canvas').getContext('2d')
    this.chart = new Chart(ctx, {
      type: 'doughnut',
      data: {
        labels: this.chartLabels,
        datasets: [{
          data: this.chartData,
          backgroundColor: this.chartColours,
          borderWidth: 0
        }]
      },
      options: {
        responsive: true,
        cutoutPercentage: 75,
        tooltips: {
          enabled: false
        },
        legend: {
          display: false
        },
        aspectRatio: 1
      }
    })
  }

  mounted (): void {
    this.initialiseChartData()
  }
}

