
import { Component, Vue, Prop } from 'vue-property-decorator'
import { IContactPersonSignUpForm } from '@/types/promoterSignup'
import PasswordFields from '@/components/forms/fields/PasswordFields.vue'
import TermOfAddressField from '@/components/forms/fields/TermOfAddressField.vue'
@Component({
  components: {
    PasswordFields,
    TermOfAddressField
  }
})
export default class ContactPersonForm extends Vue {
  @Prop() form!: IContactPersonSignUpForm
  @Prop() formErrors!: IContactPersonSignUpForm
  @Prop() passwordState: 'weak' | 'medium' | 'strong' | 'notMatching' | null
  @Prop() requiredFields!: string[]

  clicked (): void {
    this.$emit('clicked')
  }

  back (): void {
    this.$emit('back')
  }
}
