
import { Component, Prop, Vue, Watch } from 'vue-property-decorator'

@Component({
  name: 'email-input'
})
export default class EmailInput extends Vue {
  @Prop() value!: string
  @Prop({ default: true }) showPrepend: boolean
  @Prop({ default: null }) message!: string

  @Watch('email')
  onEmailChange (): void {
    this.$emit("update:message", null)
  }

  get valueModel (): string {
    return this.value
  }

  set valueModel (val: string) {
    this.$emit("input", val)
  }

  get errorMessage (): string | null {
    if (!this.value.length && !this.message) return null
    if (this.message) return this.message
    if (this.value.length > 6 && !this.validateEmail(this.value)) {
      return this.$gettext("Please enter a valid email address")
    }
    return null
  }

  get state (): boolean | null {
    if (this.errorMessage) return false
    if (this.validateEmail(this.value)) return true
    return null
  }

  validateEmail (value: string): boolean {
    const isValid = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(value)
    if (isValid) this.$emit('valid')
    else this.$emit('invalid')
    return isValid
  }
}
