
import { Component, Prop, Vue } from 'vue-property-decorator'

@Component({
  name: 'form-module'
})
export default class FormModule extends Vue {
  @Prop() title!: string
  @Prop() slug!: string
  @Prop({ default: false }) editable!: boolean
  @Prop() placeholder: string
  @Prop() state: boolean
  @Prop({ default: '' }) errorText!: string
}
