
import { Component, Mixins } from 'vue-property-decorator'
import { API_URLS, isZO } from '@/utils/helpers'
import { EnabledFeatures } from '@/types/features'
import axios from 'axios'
import ToastMixin from '@/mixins/ToastMixin'
import UserMixin from '@/mixins/UserMixin'

@Component({
  name: 'login-form'
})
export default class LoginForm extends Mixins(ToastMixin, UserMixin) {
  // TODO: DISCUSS IF EXTRA API POINT NEEDED FOR THIS
  tryLogin = false
  passwortResetUrl = API_URLS.ACCOUNT.PASSWORD.RESET
  signUpUrl = ''
  enabled_features: EnabledFeatures
  formData = {
    identification: '',
    password: '',
    remember_me: false
  }

  getSignUpUrl () {
    this.signUpUrl = this.isZO
      ? API_URLS.SIGNUP.ORGANIZATION
      : this.enabled_features.enable_user_companies || this.enabled_features.enable_user_projects
        ? API_URLS.SIGNUP.CHOOSE
        : API_URLS.SIGNUP.SIGNUP
  }

  async checkFeature (features: string) {
    return axios.get(
      API_URLS.PLATFORMFEATURES.CHECK_FEATURE_ANONYMOUS(features)
    ).then(response => {
      return response.data
    })
  }

  get isZO () {
    return isZO()
  }

  async submitForm (event: Event): Promise<void> {
    event.preventDefault()
    this.tryLogin = true
    await axios.post(API_URLS.V3.LOGIN, this.formData)
      .then(() => {
        this.$emit('success')
        this.fetchUserProfile(true)
        this.makeToast(
          'success',
          this.$gettext('Logged in'),
          ''
        )
      })
      .catch(error => {
        this.makeToast(
          'danger',
          this.$gettext('Error'),
          error.response.data.non_field_errors
        )
      })
    this.tryLogin = false
  }

  async created (): Promise<void> {
    this.enabled_features = await this.checkFeature('enable_user_companies,enable_user_projects')
    this.getSignUpUrl()
  }
}
