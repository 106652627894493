
import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import axios from 'axios'
import { BvTableFieldArray } from 'bootstrap-vue'
import DeleteModal from '@/components/modals/DeleteModal.vue'
import ReminderForm from '@/components/reminders/ReminderForm.vue'
import ReminderStatus from '@/components/reminders/ReminderStatus.vue'
import ReminderDetails from '@/components/reminders/ReminderDetails.vue'
import { TGenericObject } from '@/types/base'
import { IReminder, IReminderTemplate } from '@/types/reminders'
import { API_URLS } from '@/utils/helpers'
import { IOrganization, IProject } from '@/types/projects'
import { IUser } from '@/types/users'

@Component({
  components: {
    ReminderForm,
    ReminderStatus,
    ReminderDetails,
    DeleteModal
  }
})
export default class ReminderModal extends Vue {
  @Prop({ default: false }) value!: boolean
  @Prop({ default: null }) project!: IProject | null
  @Prop({ default: null }) organization!: IOrganization | null

  reminders: IReminder[] = []
  selectedReminder: IReminder = null
  reminderTemplates: IReminderTemplate[] = []
  selectedReminderTemplate: IReminderTemplate = null
  selectedReminderTemplateId = 0
  editActive = false
  tableLoading = false
  deleteModalOpen = false

  fields: BvTableFieldArray = [
    "status",
    { key: "title", label: this.$gettext("Task") },
    {
      key: "scheduled_for",
      label: this.$gettext("Due date"),
      formatter: this.formatDate
    },
    {
      key: "assignees",
      label: this.$gettext("Assignees"),
      formatter: (x: undefined, y: string, reminder: IReminder) =>
        reminder.users.map((user) => user.full_name_w_username).join(", ")
    },
    {
      key: "created_by",
      label: this.$gettext("Created by"),
      formatter: (createdBy: IUser) => createdBy.full_name_w_username
    }
  ]

  @Watch("selectedReminderTemplateId")
  onSelectedReminderTemplateIdChange(): void {
    if (!this.selectedReminderTemplateId) {
      this.selectedReminderTemplate = null
    }

    const filteredTemplates = this.reminderTemplates.filter(
      (template) => template.id === this.selectedReminderTemplateId
    )

    if (filteredTemplates.length === 1) {
      this.selectedReminderTemplate = filteredTemplates[0]
    }
  }

  get isOpened (): boolean {
    return this.value
  }

  set isOpened(value: boolean) {
    this.$emit("input", value)
  }

  openDeleteModal(reminder: IReminder): void {
    this.deleteModalOpen = true
    this.selectedReminder = reminder
  }

  async confirmDelete(reminder: IReminder): Promise<void> {
    axios.delete(API_URLS.REMINDERS.RETRIEVE(reminder.slug)).then(() => {
      this.deleteModalOpen = false
      return this.fetchReminderDetails()
    })
  }

  formatDate(dateStr: string): string {
    const date = new Date(dateStr)
    const day = date.getDate() < 10 ? `0${date.getDate()}` : date.getDate()
    const month = date.getMonth() < 9 ? `0${date.getMonth() + 1}` : date.getMonth() + 1
    return `${day}.${month}.${date.getFullYear()}`
  }

  async resolveReminder(reminder: IReminder): Promise<void> {
    await axios.post(API_URLS.REMINDERS.RESOLVE(reminder.slug), {
      resolve: true
    })
    this.fetchReminderDetails()
  }

  selectReminder(reminder: IReminder, edit = true): void {
    this.selectedReminder = reminder
    this.editActive = edit
  }

  cancelEdit(): void {
    this.selectedReminder = null
    this.editActive = false
  }

  closeDetails(): void {
    this.selectedReminder = null
    this.editActive = false
  }

  // does not gets called anywhere in the component
  /* get locale (): string {
    if (this.$language.current) {
      return this.$language.current.startsWith('de_') ? 'de' : this.$language.current.replace('_', '-')
    }
    return 'en-US'
  } */

  get reminderTemplateOptions(): TGenericObject[] {
    const defaultOptions: TGenericObject[] = [{ value: 0, text: "----------" }]

    const additionalOptions = this.reminderTemplates.map((reminderTemplate) => {
      return {
        value: reminderTemplate.id,
        text: reminderTemplate.title
      }
    })

    return defaultOptions.concat(additionalOptions)
  }

  get reminderDetailsUrl(): string {
    if (!(this.organization || this.project)) {
      return null
    }

    return this.project ? API_URLS.REMINDERS.PROJECT_LIST(this.project.id) : API_URLS.REMINDERS.ORGANIZATION_LIST(this.organization.id)
  }

  async fetchReminderDetails(): Promise<void> {
    if (!this.reminderDetailsUrl) {
      return
    }

    this.tableLoading = true

    await axios.get(this.reminderDetailsUrl).then((response) => {
      this.reminders = response.data.results
    })

    this.tableLoading = false
  }

  async fetchReminderTemplates(): Promise<void> {
    axios.get(API_URLS.REMINDERS.TEMPLATES.LIST).then((response) => {
      this.reminderTemplates = response.data.results
    })
  }

  async created(): Promise<void> {
    this.fetchReminderDetails()
    this.fetchReminderTemplates()
  }
}
