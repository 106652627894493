
import { Component, Prop, Vue } from 'vue-property-decorator'
import LoginForm from '@/components/forms/LoginForm.vue'

@Component({
  name: 'login-modal',
  components: {
    LoginForm
  }
})

export default class LoginModal extends Vue {
    @Prop({ default: false }) value!: boolean

    get isOpenModal (): boolean {
      return this.value
    }

    set isOpenModal (value: boolean) {
      this.$emit('input', value)
    }
}
