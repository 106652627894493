
import { Vue, Component, Prop, Emit, Watch } from 'vue-property-decorator'

@Component
export default class ConfirmModal extends Vue {
  @Prop({ required: true }) show!: boolean
  @Prop({ default: false }) noCancelBtn!: boolean
  @Prop({ required: false }) confirmBtnText!: string
  @Prop({ required: false }) cancelBtnText!: string

  localShow = false

  mounted () {
    this.localShow = this.show
  }

  @Watch('show')
  onShowChanged(value: boolean) {
    this.localShow = value
  }

  get _confirmBtnText() {
    if (this.confirmBtnText) {
      return this.confirmBtnText
    } else {
      return this.$gettext('Confirm')
    }
  }

  get _cancelBtnText() {
    if (this.cancelBtnText) {
      return this.cancelBtnText
    } else {
      return this.$gettext('Cancel')
    }
  }

  onConfirm() {
    this.localShow = false
    this.$emit('confirm')
  }

  @Emit('update:show')
  onHide() {
    this.localShow = false
    return false
  }

  @Emit('update:show')
  onCancel() {
    this.localShow = false
    return false
  }
}
