
import LoginModal from '@/components/modals/LoginModal.vue'
import ToastMixin from '@/mixins/ToastMixin'
import UserMixin from '@/mixins/UserMixin'
import { IProjectDetailSettings } from '@/types/cms'
import { IMilestone } from '@/types/finances'
import { ICustomTab, IExploreProjectDetail } from "@/types/projects"
import { API_URLS } from '@/utils/helpers'
import axios from 'axios'
import { Component, Mixins, Prop } from 'vue-property-decorator'
import ProjectCustomTab from './ProjectCustomTab.vue'
import ImageSlider from '@/components/explore/ImageSlider.vue'
import { IExploreOrganizationDetail } from '@/types/organizations'
@Component({
  components: {
    ProjectCustomTab,
    LoginModal,
    ImageSlider
  }
})
export default class ProjectOverview extends Mixins(ToastMixin, UserMixin) {
  @Prop() project!: IExploreProjectDetail | IExploreOrganizationDetail
  @Prop({ default: null }) projectDetailSettings: IProjectDetailSettings

  loginModalOpen = false
  favouriteMouseover = false
  tabKeys = {
    Append: 1,
    ProjectPromoterDescriptionPre: 100,
    ProjectPromoterDescriptionPost: 101,
    ProjectPromoterGalleryPost: 102,
    ProjectPromoterProjectsPost: 103,
    ProjectPromoterDonationUsagePost: 104,
    ProjectPromoterVotingsPost: 105,
    ProjectPromoterStatisticsPost: 106,
    ProjectPromoterSupportPost: 107,
    ProjectDescriptionPre: 200,
    ProjectDescriptionPost: 201,
    ProjectFundingNeedsPost: 202,
    ProjectGalleryPost: 203,
    ProjectOrganizationPost: 204,
    ProjectStatisticsPost: 205,
    ProjectVotingRankPost: 206,
    ProjectSupportPost: 207
  }

  get isFavourite (): boolean {
    if (this.userProfile?.favorite_projects) {
      return this.userProfile.favorite_projects.filter(fav => fav.slug === this.project.slug).length > 0
    }
    return false
  }

  get favouriteIconClass (): string {
    if (this.isFavourite) {
      return 'fad'
    } else if (this.favouriteMouseover) {
      return 'fas'
    }
    return 'far'
  }

  customTabs (tabKey: number): ICustomTab[] {
    return this.project.custom_tabs[tabKey]
  }

  async setFavourite () {
    if (this.userProfile.is_anonymous) {
      this.loginModalOpen = true
      return true
    }

    if (!this.isFavourite) {
      await axios.post(
        API_URLS.EXPLORE.PROJECTS.FAVOURITE(this.project.slug)
      ).then(() => {
        this.makeToast('success', this.$gettext('Added new favorite'), this.project.title)
      }).catch(() => {
        this.makeToast('danger', 'Error', 'Failed to add favorite')
      })
    } else {
      await axios.post(API_URLS.EXPLORE.PROJECTS.REMOVE_FAVOURITE(this.project.slug)
      ).then(() => {
        this.makeToast('warning', this.$gettext('Removed favorite'), this.project.title)
      }).catch(() => {
        this.makeToast('danger', 'Error', 'Failed to remove favorite')
      })
    }

    this.fetchFavoriteProjects()
  }

  progressText (goal: IMilestone): string {
    if (this.projectDetailSettings.currencyDisplay.useCurrency) {
      return this.$gettextInterpolate(
        this.$gettext('%{ currentBalance } of %{ totalBalance }'),
        {
          currentBalance: goal.balance.in_currency_display,
          totalBalance: goal.goal.in_currency_display
        }
      )
    } else {
      return this.$gettextInterpolate(
        this.$gettext('%{ currentBalance } of %{ totalBalance } %{ coinName }'),
        {
          currentBalance: goal.balance.in_coins,
          totalBalance: goal.goal.in_coins,
          coinName: this.projectDetailSettings.currencyDisplay.coinNamePlural
        }
      )
    }
  }
}
