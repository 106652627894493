
import { Vue, Component, Prop } from 'vue-property-decorator'
import InfoBanner from '@/components/processing_steps/banners/InfoBanner.vue'
import axios from 'axios'

@Component({
  components: {
    InfoBanner
  }
})
export default class OrgaNotSubmittedBanner extends Vue {
  @Prop({ default: false }) isSiteAdmin!: boolean
  @Prop() orgaSlug!: string | null

  showInfoBanner = false

  mounted () {
    this.setShowInfoBanner()
  }

  setShowInfoBanner (): void {
    if (this.isSiteAdmin) return
    let url = "/api/v4/processing-steps/show-info-banner/"
    if (this.orgaSlug) {
      url += `?orga_slug=${this.orgaSlug}/`
    }
    axios.get(url).then((response) => {
      this.showInfoBanner = response.data.show_orga_not_submitted_banner
      this.$emit('fetched', this.showInfoBanner)
    })
  }

  closeInfoModal () {
    this.showInfoBanner = false
    this.$emit('close-info-modal')
  }
}

