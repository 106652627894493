
import { Component, Prop, Vue } from 'vue-property-decorator'
import { IOrganization } from '@/types/projects'

@Component({
  name: 'organization-status'
})
export default class OrganizationStatus extends Vue {
  @Prop() organization!: IOrganization

  get label (): string {
    let labelHtml = ''
    const status = this.organization.status
    if (status.choice === 1) labelHtml = `<span class="badge badge-warning">${status.display}</span>` // draft
    else if (status.choice === 2) labelHtml = `<span class="badge badge-success">${status.display}</span>` // public
    else if (status.choice === 3) labelHtml = `<span class="badge badge-light">${status.display}</span>` // archived
    return labelHtml
  }
}
