
import { Component, Prop, Vue, Watch } from "vue-property-decorator"
import axios from "axios"
import { API_URLS } from "@/utils/helpers"
import { IOrganization, IProject } from "@/types/projects"

@Component({
  name: "info-modal"
})
export default class InfoModal extends Vue {
  @Prop({ default: false }) value!: boolean
  @Prop({ default: null }) project!: IProject
  @Prop({ default: null }) organization!: IOrganization
  infoModalContent = ""

  @Watch("project")
  onProjectChange(): void {
    this.fetchModalContent()
  }

  @Watch("organization")
  onOrganizationChange(): void {
    this.fetchModalContent()
  }

  get modalTitle(): string {
    if (this.project) return this.project.title
    else if (this.organization) return this.organization.title
    return ""
  }

  get infoUrl(): string {
    if (!(this.organization || this.project)) return ""
    if (this.project) return API_URLS.PROJECTS.INFO(this.project.slug)
    else return API_URLS.ORGANIZATIONS.INFO(this.organization.slug)
  }

  get isOpenModel(): boolean {
    return this.value
  }

  set isOpenModel(value: boolean) {
    this.$emit("input", value)
  }

  async fetchModalContent(): Promise<void> {
    this.$wait.start("fetch info")
    await axios
      .get(this.infoUrl)
      .then((response) => {
        this.infoModalContent = response.data
      })
      .catch(() => {
        if (this.project) {
          this.infoModalContent = this.$gettext("Failed to load project data")
        } else if (this.organization) {
          this.infoModalContent = this.$gettext(
            "Failed to load organization data"
          )
        }
      })
    this.$wait.end("fetch info")
  }

  async created(): Promise<void> {
    this.fetchModalContent()
  }
}
