
import LocaleMixin from '@/mixins/LocaleMixin'
import { computed, ref, watch } from 'vue'
import { useCurrencyInput } from 'vue-currency-input'
import { Prop, Component, Mixins, Ref } from 'vue-property-decorator'

@Component({
  name: 'currency-input',
  setup (props) {
    const valueAdjusted = ref(false)
    let timer = null
    const options = computed(() => ({ currency: props.currency }))
    const { inputRef, setOptions, setValue } = useCurrencyInput(options.value)
    setOptions({
      currency: props.currency
    })
    watch(
      () => props.value,
      (value) => {
        if ((Math.abs(Math.round(value * 100) % Math.round(props.stepSize * 100) / 100)) < 1e-10) {
          clearTimeout(timer)
          setValue(value)
        } else {
          const factor = Math.floor(Math.round(value / props.stepSize))
          const adjustedValue = factor * props.stepSize
          clearTimeout(timer)
          timer = setTimeout(() => {
            setValue(adjustedValue)
          }, 200)
          valueAdjusted.value = true
        }
      }
    )
    return { inputRef, valueAdjusted }
  }
})
export default class CurrencyInput extends Mixins(LocaleMixin) {
  @Ref() inputRef!: object
  @Prop() value!: number
  @Prop() currency!: string
  @Prop({ default: 0.1 }) stepSize!: number
  @Prop({ default: null }) placeholder: string
  @Prop({ default: false }) disabled: boolean
  @Prop({ default: null }) limits: { min_in_cents: number, max_in_cents: number }
  @Prop({ default: true }) showPrepend!: boolean // Make this a slot if we need it more often

  get valueInCents (): number {
    return Math.round(this.value * 100)
  }

  get errorMessage (): string {
    if (!this.value || !this.limits) return null
    if (this.valueInCents > this.limits.max_in_cents) {
      return this.$gettextInterpolate(
        this.$gettext("The maximum amount you can currently to donate to this project is %{ maxAmount }"),
        { maxAmount: this.centsToCurrency(this.limits.max_in_cents) }
      )
    } else if (this.valueInCents < this.limits.min_in_cents) {
      return this.$gettextInterpolate(
        this.$gettext("The minimum amount you can currently to donate to this project is %{ minAmount }"),
        { minAmount: this.centsToCurrency(this.limits.min_in_cents) }
      )
    }
    return null
  }

  get validationState (): boolean {
    if (!this.limits) {
      return null
    }
    if (this.value) {
      if (this.errorMessage) this.$emit('invalid')
      else this.$emit('valid')
      return !this.errorMessage
    }
    this.$emit('invalid')
    return null
  }

  adjustAmount (): void {
    if (this.valueInCents > this.limits.max_in_cents) {
      this.$emit('input', this.limits.max_in_cents / 100)
    } else if (this.valueInCents < this.limits.min_in_cents) {
      this.$emit('input', this.limits.min_in_cents / 100)
    }
  }

  get defaultPlaceholder (): string {
    return this.$gettext('Donation amount') + ' *'
  }

  created () {
    useCurrencyInput({ currency: this.currency })
  }
}
