
import { Component, Prop, Vue } from 'vue-property-decorator'
import FormModule from '@/components/FormModule.vue'
import { IDocumentBaseForm } from '@/types/documents'

@Component({
  name: 'base-information-step',
  components: {
    FormModule
  }
})
export default class BaseInformationStep extends Vue {
  @Prop() value!: IDocumentBaseForm
  @Prop({ default: false }) saving!: boolean

  documentTypeOptions: { text: string, value: string }[] = [
    { text: this.$gettext('Custom document'), value: '32767' },
    { text: this.$gettext('Custom donation receipt'), value: '290' },
    { text: this.$gettext('Custom gewinnspar receipt'), value: '390' }
  ]

  submitForm (event: Event): void {
    event.preventDefault()
    this.$emit('submit', event)
  }
}
