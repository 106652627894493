
import { Prop, Component, Mixins } from 'vue-property-decorator'
import { IExploreProjectDetail, IDonationResult } from '@/types/projects'
import { IProjectDetailSettings, ICurrencyDisplaySettings } from '@/types/cms'
import LocaleMixin from '@/mixins/LocaleMixin'
import { IUserProfile } from '@/types/users'
import SocialMediaArea from '../explore/SocialMediaArea.vue'

@Component({
  name: 'donation-success-modal',
  components: {
    SocialMediaArea
  }
})
export default class DonationSuccessModal extends Mixins(LocaleMixin) {
  @Prop() project!: IExploreProjectDetail
  @Prop({ default: null }) projectDetailSettings: IProjectDetailSettings
  @Prop({ default: null }) donationResult: IDonationResult
  @Prop({ default: null }) userProfile: IUserProfile
  @Prop({ default: false }) value!: boolean

  get isOpenModel (): boolean {
    return this.value
  }

  set isOpenModel (value: boolean) {
    this.$emit('input', value)
  }

  get currencyDisplay (): ICurrencyDisplaySettings {
    return this.projectDetailSettings?.currencyDisplay
  }

  formatCurrency (coinVal: string, centsVal: string): string {
    if (this.currencyDisplay.useCurrency) {
      return `${centsVal} ${this.currencyDisplay.currencySymbol}`
    }
    return `${coinVal} ${this.currencyDisplay.coinNamePlural}`
  }
}
