
import { Component, Prop, Vue } from 'vue-property-decorator'

@Component({
  name: 'facebook-share-button'
})
export default class FacebookButton extends Vue {
  @Prop({ default: 'transparent' }) variant: string
  @Prop({ default: 'smb-fb-foerder-app' }) customClass: string
  @Prop() hashtags: string[]

  get shareUrl (): string {
    // Using parameters from old code, but facebook only allows 1 hashtag now
    // Also removed "quote" param (to fill in text?) because facebook must have removed that too
    return (
      'https://www.facebook.com/sharer/sharer.php?display=popup' +
      `&u=${location.protocol + '//' + location.host + location.pathname}` +
      `&hashtag=${this.hashtags.map(hashtag => '%23' + hashtag).join(',')}`
    )
  }

  openPopup (): void {
    window.open(this.shareUrl, 'pop', 'width=600, height=400, scrollbars=no')
  }
}
