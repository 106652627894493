
import {
  Action,
  Module,
  Mutation,
  MutationAction,
  VuexModule,
  getModule
} from 'vuex-module-decorators'

import store from '@/store'
import { TGenericObject } from '@/types/base'
import { IPermissions, IPermissionsForUsers, IPermissionsState, IPermissionsWithUsers, IPermissionsWithUsersResponse } from '@/types/permissions'
import { API_URLS } from '@/utils/helpers'

@Module({ dynamic: true, store, name: 'permissions' })
class Permissions extends VuexModule implements IPermissionsState {
  permissions: IPermissions = {}
  activePermission = ''
  permissionTypes: TGenericObject = {}
  permissionFilters: string[] = []
  selectedPermissions: string[] = []
  permissionsWithUsers: IPermissionsWithUsers = {}
  selectedUsers: string[] = []
  ignoreFilters = false

  get filteredUsers (): IPermissionsForUsers {
    if (!this.activePermission || this.permissionsWithUsers[this.activePermission] === undefined) {
      return {}
    }

    const users = this.permissionsWithUsers[this.activePermission].users
    const permissionTypeKeys = Object.keys(this.permissionTypes)

    return Object.assign({}, ...Object.keys(users)
      .filter(username => users[username].some(perm => this.permissionFilters.includes(perm) || !permissionTypeKeys.includes(perm)))
      .map(username => ({ [username]: users[username] })))
  }

  @MutationAction({ mutate: ['permissions'] })
  async fetchPermissions () {
    const res = await fetch(API_URLS.PERMISSIONS.LIST)
      .then(res => {
        if (!res.ok) { throw new Error(res.statusText) }
        return res.json()
      })
    return { permissions: res }
  }

  @MutationAction({ mutate: ['activePermission'] })
  async setActivePermission (activePermission: string) {
    return { activePermission }
  }

  @Mutation
  ADD_PERMISSION_WITH_USERS (permNameAndPermWithUsers: { permissionName: string, permissionsWithUsers: IPermissionsWithUsersResponse }) {
    const permName = permNameAndPermWithUsers.permissionName as string
    this.permissionTypes = permNameAndPermWithUsers.permissionsWithUsers.permission_types
    this.permissionsWithUsers = { ...this.permissionsWithUsers, [permName]: permNameAndPermWithUsers.permissionsWithUsers }
  }

  @Action({ commit: 'ADD_PERMISSION_WITH_USERS' })
  async fetchPermissionWithUsers (permissionName: string) {
    const res = await fetch(API_URLS.PERMISSIONS.USERS(permissionName))
      .then(res => {
        if (!res.ok) { throw new Error(res.statusText) }
        return res.json()
      })
    if (res.permission_types) res.permissionTypes = res.permission_types
    return { permissionName, permissionsWithUsers: res }
  }

  @Mutation
  SET_SELECTED_USERS (selectedUsers: Array<string>) {
    this.selectedUsers = selectedUsers
  }

  @Action({ commit: 'SET_SELECTED_USERS' })
  addUser (username: string) {
    if (username === 'all') { return Object.keys(this.filteredUsers) }
    return [...this.selectedUsers, username]
  }

  @Action({ commit: 'SET_SELECTED_USERS' })
  removeUser (username: string) {
    if (username === 'all') { return [] }
    return this.selectedUsers.filter(x => x !== username)
  }

  @Mutation
  SET_PERMISSION_FILTERS (permissionFilters: string[]) {
    this.permissionFilters = permissionFilters
  }

  @Action({ commit: 'SET_PERMISSION_FILTERS' })
  addPermissionFilter (permissionName: string) {
    return [...this.permissionFilters, permissionName]
  }

  @Action({ commit: 'SET_PERMISSION_FILTERS' })
  addPermissionFilters (permissionNames: string[]) {
    return [...this.permissionFilters, ...permissionNames]
  }

  @Action({ commit: 'SET_PERMISSION_FILTERS' })
  removePermissionFilter (permissionName: string) {
    return this.permissionFilters.filter(x => x !== permissionName)
  }

  @Action({ commit: 'SET_PERMISSION_FILTERS' })
  removePermissionFilters (permissionNames: string[]) {
    return this.permissionFilters.filter(x => !permissionNames.includes(x))
  }

  @Mutation
  SET_SELECTED_PERMISSION (permissionKey: string) {
    this.selectedPermissions = [...this.selectedPermissions, permissionKey]
  }

  @Mutation
  SET_SELECTED_PERMISSIONS (selectedPermissions: string[]) {
    this.selectedPermissions = selectedPermissions
  }

  @Action({ commit: 'SET_SELECTED_PERMISSION' })
  addSelectedPermission (permissionKey: string) {
    return permissionKey
  }

  @Action({ commit: 'SET_SELECTED_PERMISSIONS' })
  removeSelectedPermission (permissionKey: string) {
    return this.selectedPermissions.filter(x => x !== permissionKey)
  }

  @Action({ commit: 'SET_SELECTED_PERMISSIONS' })
  clearSelectedPermissions () {
    return []
  }

  @Mutation
  private SET_IGNORE_FILTERS (value: boolean): void {
    this.ignoreFilters = value
  }

  @Action({ commit: 'SET_IGNORE_FILTERS' })
  public updateIgnoreFilters (value: boolean): boolean {
    return value
  }
}

export const PermissionsModule = getModule(Permissions)
