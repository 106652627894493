
import { Component, Vue, Prop } from 'vue-property-decorator'
import FacebookShareButton from '@/components/buttons/FacebookShareButton.vue'
import WhatsappShareButton from '@/components/buttons/WhatsappShareButton.vue'
import TwitterShareButton from '@/components/buttons/TwitterShareButton.vue'
import EmailShareButton from '@/components/buttons/EmailShareButton.vue'
import { IExploreProjectDetail } from '@/types/projects'
import { IProjectDetailSettings } from '@/types/cms'

@Component({
  name: 'social-media-area-foerder-app',
  components: {
    FacebookShareButton,
    WhatsappShareButton,
    TwitterShareButton,
    EmailShareButton
  }
})
export default class SocialMediaAreaFoerderApp extends Vue {
  @Prop() project!: IExploreProjectDetail
  @Prop({ default: null }) projectDetailSettings: IProjectDetailSettings
}
