
import { Component, Vue } from 'vue-property-decorator'
import SfAlert from '@/components/SfAlert.vue'

@Component({
  name: 'verify-vote-modal',
  components: {
    SfAlert
  }
})
export default class VerifyVoteModal extends Vue {
}
