
import { Component, Prop, Vue } from 'vue-property-decorator'
import { IProject } from '@/types/projects'

@Component({
  name: 'project-status'
})
export default class ProjectStatus extends Vue {
  @Prop() project!: IProject

  get label (): string {
    let labelHtml = ''
    const status = this.project.status
    if (status.choice === 1) labelHtml = `<span class="badge badge-warning">${status.display}</span>` // draft
    else if (status.choice === 2) labelHtml = `<span class="badge badge-success">${status.display}</span>` // public
    else if (status.choice === 3) labelHtml = `<span class="badge badge-light">${status.display}</span>` // archived
    return labelHtml
  }
}
