

import { Component, Prop, Vue } from 'vue-property-decorator'

@Component
export default class ProjectLinkButton extends Vue {
  @Prop({ required: false }) href!: string
  @Prop({ required: false }) route!: string
  @Prop() tooltip!: string
  @Prop({ default: false }) disabled

  get TooltipDisplay () {
    return this.tooltip ? this.tooltip : 'Project url'
  }
}
