

import { Component, Prop, Vue } from 'vue-property-decorator'

@Component
export default class IconButton extends Vue {
  @Prop({ required: false }) href!: string
  @Prop({ default: null }) tooltip!: string | null
  @Prop({ default: null }) iconClass: string | null
  @Prop({ default: false }) disabled: boolean

  onButtonClicked (): void {
    this.$emit("btn-clicked")
  }
}
