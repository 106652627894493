
import { IExploreProjectDetail } from '@/types/projects'
import { Component, Prop, Vue } from 'vue-property-decorator'
import CircularProgressBar from './CircularProgressBar.vue'
import { ExploreModule } from '@/store/modules/explore'
import { IProjectDetailSettings } from '@/types/cms'
import { IExploreOrganizationDetail } from '@/types/organizations'

@Component({
  name: 'donation-info',
  components: {
    CircularProgressBar
  }
})
export default class DonationInfo extends Vue {
  @Prop() project!: IExploreProjectDetail | IExploreOrganizationDetail
  @Prop({ default: false }) isOrganization!: boolean

  get projectDetailSettings (): IProjectDetailSettings {
    return ExploreModule.projectDetailSettings
  }

  get displayBalance (): string {
    if (!('goals' in this.project)) {
      return this.project.balance.in_currency_display
    }
    // If project is archived or displayCurrentCoinBalance is not set to "Goal" (showing balance of goal)
    if (this.project.is_status_archived || !(this.projectDetailSettings?.displayCurrentCoinBalance === 2)) {
      return this.project.balance.in_currency_display
    }
    return this.project.current_goal_balance.in_currency_display
  }

  get displayGoal (): string {
    if (!('goals' in this.project)) {
      return ''
    }
    // If project is archived or displayCurrentCoinBalance is not set to "Goal" (showing value of goal)
    if (this.project.is_status_archived || !(this.projectDetailSettings?.displayCurrentCoinBalance === 2)) {
      return this.project.total_goal_amount.in_currency_display
    }
    return this.project.current_goal.in_currency_display
  }

  get balanceReachedInPercent (): number {
    if (!('goals' in this.project) || !this.project.total_goal_amount.in_cents) return 0
    return (this.project.balance.in_cents / this.project.total_goal_amount.in_cents) * 100
  }

  get otherDonationAmount (): number {
    const knownSum = this.project.funded_by_co_funding.in_cents + this.project.funded_by_codes.in_cents + this.project.funded_by_private_donations.in_cents
    return this.project.balance.in_cents - knownSum
  }

  get otherDonationAmountInPercent (): number {
    if (!('goals' in this.project) || !this.otherDonationAmount) return 0
    return (this.otherDonationAmount / this.project.total_goal_amount.in_cents) * 100
  }

  get coFundingFundingInPercent (): number {
    if (!('goals' in this.project) || !this.project.funded_by_co_funding.in_cents) return 0
    return (this.project.funded_by_co_funding.in_cents / this.project.total_goal_amount.in_cents) * 100
  }

  get privateFundingInPercent (): number {
    if (!('goals' in this.project) || !this.project.funded_by_private_donations.in_cents) return 0
    return (this.project.funded_by_private_donations.in_cents / this.project.total_goal_amount.in_cents) * 100
  }

  get codeFundingInPercent (): number {
    if (!('goals' in this.project) || !this.project.funded_by_codes.in_cents) return 0
    return (this.project.funded_by_codes.in_cents / this.project.total_goal_amount.in_cents) * 100
  }

  get showOverallGoal (): boolean {
    if (this.project?.is_status_archived) return true
    return this.projectDetailSettings?.displayCurrentCoinBalance === 1
  }

  get showCurrentGoal (): boolean {
    return this.projectDetailSettings?.displayCurrentCoinBalance === 2 && this.displayGoal !== ''
  }

  get showOverallAndCurrentGoal (): boolean {
    return this.projectDetailSettings?.displayCurrentCoinBalance === 4 && this.displayGoal !== ''
  }

  get showOnlyCollectedFunding (): boolean {
    return this.projectDetailSettings?.displayCurrentCoinBalance === 5 && this.displayGoal !== ''
  }
}
