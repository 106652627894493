
import { Component, Mixins, Prop } from 'vue-property-decorator'

import LocaleMixin from '@/mixins/LocaleMixin'
import { IExploreProjectMinimal } from '@/types/projects'
import { TGenericObject } from '@/types/base'

@Component({
  name: 'showcase-project-card'
})
export default class ShowcaseProjectCard extends Mixins(LocaleMixin) {
  @Prop() project!: IExploreProjectMinimal
  @Prop() customBtnStyle: TGenericObject
  @Prop({ required: false }) affiliateParam: string

  get openLinkInNewTab (): boolean {
    /* eslint-disable object-shorthand */
    return this.$route.query?.widget === 'true' || this.$route.path.endsWith('/widget/')
  }

  truncate (title: string, length: number): string {
    if (title.length > length) {
      return `${title.slice(0, length - 3)} ...`
    }
    return title
  }

  openProject () {
    const params = { projectSlug: this.project.slug }
    if (this.affiliateParam) {
      const query = { showcase: this.affiliateParam }
      if (this.openLinkInNewTab) {
        window.open(this.$router.resolve({ name: 'project-detail', params: params, query: query }).href, '_blank')
        return
      }
      this.$router.push({ name: 'project-detail', params: params, query: query })
    } else {
      if (this.openLinkInNewTab) {
        window.open(this.$router.resolve({ name: 'project-detail', params: params }).href, '_blank')
        return
      }
      this.$router.push({ name: 'project-detail', params: params })
    }
  }
}
