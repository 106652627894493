

import { Component, Prop, Vue } from 'vue-property-decorator'

@Component
export default class AbortButton extends Vue {
  @Prop({ required: false }) href!: string
  @Prop({ required: false }) route!: string
  @Prop({ default: false }) disabled
  @Prop({ required: false }) tooltip: string

  get tooltipDisplay () {
    if (this.tooltip) {
      return this.tooltip
    }
    return this.$gettext("Cancel")
  }

  onButtonClicked (): void {
    this.$emit("btn-clicked")
  }
}
