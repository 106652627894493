
import { IFilter, IFilters } from '@/types/filters'
import { addFiltersToUrl } from '@/utils/helpers'
import { Component, Vue } from 'vue-property-decorator'

@Component({
  name: 'generic-table-mixin'
})
export default class GenericTableMixin extends Vue {
  /*
  Use this Mixin in a List.vue that uses a GenericTable to reduce the amount of boilerplate.
  */
  searchString = ''
  filterCols: { field: string, filter: string }[] = []
  filters: IFilters = {}
  activeTableFilters: IFilter[] | null = null

  tableFilterUpdated (filters: IFilter[]): void {
    this.activeTableFilters = filters
  }

  searchUpdated (newSearch: string): void {
    this.searchString = newSearch
  }

  getFilteredExportUrl (exportUrl: (f: string) => string): string {
    let searchFilter = ''
    if (this.searchString) searchFilter = `&search=${this.searchString}`
    return exportUrl(addFiltersToUrl(this.activeTableFilters) + searchFilter)
  }
}
