
import { Component, Prop, Vue } from 'vue-property-decorator'

@Component
export default class EmailButton extends Vue {
  @Prop({ default: 'transparent' }) variant: string
  @Prop({ default: 'smb-fb-foerder-app' }) customClass: string
  @Prop() hashtags: string[]
  @Prop({ default: '' }) shareText: string
  @Prop({ default: '' }) shareLink: string

  get shareUrl (): string {
    return `mailto:?subject=${this.shareText}&body=${this.shareText}%0A%0A${this.shareLink}`
  }
}
