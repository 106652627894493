import { render, staticRenderFns } from "./UploadFileStep.vue?vue&type=template&id=256d2cfe&scoped=true&"
import script from "./UploadFileStep.vue?vue&type=script&lang=ts&"
export * from "./UploadFileStep.vue?vue&type=script&lang=ts&"
import style0 from "./UploadFileStep.vue?vue&type=style&index=0&id=256d2cfe&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "256d2cfe",
  null
  
)

export default component.exports