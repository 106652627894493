
import { Component, Vue } from 'vue-property-decorator'
import { UserModule } from '@/store/modules/user'
import { IUserProfile } from '@/types/users'
import { IBalance } from '@/types/base'

@Component({
  name: 'user-mixin'
})
export default class UserMixin extends Vue {
  get userProfile (): IUserProfile | null {
    return UserModule.profile
  }

  get userBalance (): IBalance | null {
    return this.userProfile?.balance
  }

  hasPermission (permissionName: string): boolean {
    return UserModule.hasPermission(permissionName)
  }

  async fetchUserProfile (force = false): Promise<void> {
    return UserModule.fetchProfile(force)
  }

  async fetchUserBalance (): Promise<void> {
    return UserModule.fetchBalance()
  }

  async fetchFavoriteProjects (): Promise<void> {
    return UserModule.fetchFavoriteProjects()
  }
}
