
import { Component, Prop, Vue } from 'vue-property-decorator'

import { setCookie } from '@/utils/helpers'

@Component({
  name: 'map-placeholder'
})
export default class MapPlaceholder extends Vue {
  @Prop({ default: false }) sparkasse: boolean

  COOKIE_NAME = 'cookie_rule_detail'
  REQUIRED_COOKIE_VALUE = 'google-maps'

  get cookieRuleDetail (): string {
    return this.$cookies.get(this.COOKIE_NAME)
  }

  setCookieRuleDetail (): void {
    if (this.cookieRuleDetail) {
      // The vue-cookies library doesn't provide the underlying setRaw function, so we have to do it ourselves.
      // this.$cookies.set would encode the comma, which other parts of the application expect to be unencoded
      setCookie(this.COOKIE_NAME, `${this.cookieRuleDetail},${this.REQUIRED_COOKIE_VALUE}`)
    } else {
      this.$cookies.set(this.COOKIE_NAME, this.REQUIRED_COOKIE_VALUE)
    }
  }

  onClickCookieButton () {
    this.setCookieRuleDetail()
    window.location.reload()
  }
}
