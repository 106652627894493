
import { ISelectOption, ISelectGroup, TGenericObject } from '@/types/base'
import { Component, Prop, Vue } from 'vue-property-decorator'

@Component({
  name: 'searchable-multi-select-dropdown'
})
export default class SearchableMultiSelectDropdown extends Vue {
  @Prop() value!: string[]
  @Prop() title!: string
  @Prop() items!: TGenericObject[]
  @Prop({ default: () => [] }) searchableFields!: string[]
  itemSearch = ''
  selected = []

  get inputModel (): string[] {
    return this.value
  }

  set inputModel (val: string[]) {
    this.$emit('input', val)
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  isSelectGroup (item: any): boolean {
    return item && typeof item === "object" && "options" in item
  }

  get itemOptions (): (ISelectOption | ISelectGroup)[] {
    return this.filteredItems.map((item) => {
      if (this.isSelectGroup(item)) {
        const filteredGroupOptions = this.filterGroupOptions(item.options)
        return {
          label: item.label,
          options: filteredGroupOptions
        }
      }
      return {
        value: item.slug,
        text: item.title
      }
    })
  }

  get filteredItems (): TGenericObject[] {
    const lowerCaseItemSearch = this.itemSearch.toLowerCase()

    return this.items.filter((item) => {
      if (this.isSelectGroup(item)) {
        if (item.label.toLowerCase().includes(lowerCaseItemSearch.toLowerCase())) {
          return true
        }
        const optionTexts = item.options.map((option) => option.text.toLowerCase())
        return optionTexts.some((text) => text.includes(lowerCaseItemSearch))
      }
      const title = item.title.toLowerCase()
      return title.includes(lowerCaseItemSearch)
    })
  }

  filterGroupOptions (items) {
    const lowerCaseItemSearch = this.itemSearch.toLowerCase()
    return items.filter((item) => {
      const title = item.text.toLowerCase()
      return title.includes(lowerCaseItemSearch)
    })
  }
}
