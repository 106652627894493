
import { Component, Prop, Vue } from 'vue-property-decorator'

@Component({
  name: 'code-input'
})
export default class CodeInput extends Vue {
  @Prop({ default: '' }) value!: string
  @Prop({ default: 4 }) blockLength!: number
  @Prop({ default: null }) errorMessage!: string
  @Prop({ default: null }) isValid!: string
  @Prop({ default: null }) label!: string

  get formatRegex (): RegExp {
    return new RegExp(`.{1,${this.blockLength}}`, 'g')
  }

  get displayValue (): string {
    const chunks = this.value.replaceAll(/[^\w]/gi, '').match(this.formatRegex)
    return chunks ? chunks.join(' ') : null
  }

  codeInput (value: string): void {
    this.$emit("input", value)
  }
}
