
import { IProjectDetailSettings } from '@/types/cms'
import { IExploreOrganizationDetail } from '@/types/organizations'
import { IExploreProjectDetail } from '@/types/projects'
import { Component, Prop, Vue } from 'vue-property-decorator'

@Component({
  name: 'email-share-button'
})
export default class EmailShareButton extends Vue {
  @Prop() project!: IExploreProjectDetail | IExploreOrganizationDetail
  @Prop({ default: null }) projectDetailSettings: IProjectDetailSettings

  get shareUrl (): string {
    return `mailto:?subject=${this.project.share_text}&body=${this.project.share_text}%0A%0A${this.project.share_link}`
  }

  get variant () {
    return this.projectDetailSettings.enableFoerderApp ? "transparent" : "share-mail"
  }

  get customClass () {
    return this.projectDetailSettings.enableFoerderApp ? "smb-mail-foerder-app" : ""
  }
}
