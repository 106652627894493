
import { Component, Prop, Vue } from 'vue-property-decorator'

@Component({
  name: 'map-zoom-slider'
})
export default class MapZoomSlider extends Vue {
  @Prop() value!: number

  get zoom (): number {
    return this.value
  }

  set zoom (val: number) {
    // the input value type is actually string, but getter and setter type need to be identical
    this.$emit('input', Number(val))
  }
}
