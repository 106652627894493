
import { Component, Vue, Prop } from 'vue-property-decorator'
import { IPromoterSignupForm } from '@/types/promoterSignup'
import CountryField from '@/components/forms/fields/CountryField.vue'
@Component({
  components: {
    CountryField
  }
})
export default class OrganizationForm extends Vue {
  @Prop() form!: IPromoterSignupForm
  @Prop() formErrors!: IPromoterSignupForm
  @Prop() requiredFields!: string[]

  clicked (): void {
    this.$emit('clicked')
  }

  state (field: string): boolean {
    return this.formErrors[field] !== '' ? false : null
  }

  validateEmail () {
    this.$emit('email-input')
  }

  validateTitle () {
    this.$emit('title-input')
  }
}
