
import axios from 'axios'
import { Component, Prop, Vue, Watch } from 'vue-property-decorator'

import GoogleMapLoader from '@/components/GoogleMapLoader.vue'
import MapPlaceholder from '@/components/MapPlaceholder.vue'
import ProjectCard from '@/components/explore/ProjectCard.vue'
import ProjectCardPlaceholder from '@/components/explore/ProjectCardPlaceholder.vue'
import { ExploreModule } from '@/store/modules/explore'
import { ISelectOption, TGenericObject } from '@/types/base'
import { IPageSettings } from '@/types/cms'
import { IExploreProjectMinimal, ILocation, IOrganizationExploreFilter, IProjectCategory, IProjectExploreFilters } from '@/types/projects'
import { IExploreOrganizationMinimal } from '@/types/organizations'

@Component({
  components: {
    GoogleMapLoader,
    ProjectCard,
    ProjectCardPlaceholder,
    MapPlaceholder
  },
  name: 'exploration'
})
export default class Exploration extends Vue {
  @Prop() baseExploreURL: string
  @Prop() categoriesURL: string
  @Prop() locationsURL: string
  @Prop() filters: IProjectExploreFilters | IOrganizationExploreFilter
  @Prop({ default: false }) isOrganizationExplore: boolean

  loading = false
  currentPage = 1
  itemCount = 1
  categories: IProjectCategory[] = []
  items: (IExploreProjectMinimal | IExploreOrganizationMinimal)[] = []
  locations: ILocation[] = []

  // filters: IProjectExploreFilters = {
  //   searchString: '',
  //   hideFullyFunded: true,
  //   hideExpired: true,
  //   categories: [],
  //   sortOrder: null,
  //   cmsFilter: null
  // }

  pageSettings: IPageSettings = null
  exploreHTML: string = null
  mapBoundsInitial = true
  googleMapsAccepted = false

  mapBounds = {
    lat: {
      min: null,
      max: null
    },
    lng: {
      min: null,
      max: null
    }
  }

  @Watch('filters.sortOrder')
  onSortOrderChange (): void {
    if (!this.$wait.is(['load data'])) {
      this.currentPage = 1
      this.fetchItems(false)
      this.mapBoundsInitial = true
    }
  }

  @Watch('filters.searchString')
  @Watch('filters.cmsFilter')
  @Watch('filters.categories')
  @Watch('filters.hideExpired')
  @Watch('filters.hideFullyFunded')
  onFilterChangeForAll (): void {
    if (!this.$wait.is(['load data'])) {
      this.currentPage = 1
      this.fetchItems(false)
      this.fetchLocations()
      this.mapBoundsInitial = true
    }
  }

  @Watch('mapBounds', { deep: true })
  mapBoundsChange (): void {
    if (this.mapBoundsInitial) {
      this.mapBoundsInitial = false
      return
    }
    this.currentPage = 1
    this.fetchItems()
  }

  @Watch('currentPage')
  onPageChange (): void {
    this.fetchItems(true, true)
  }

  onBoundsChanged (bounds: google.maps.LatLngBounds | undefined): void {
    if (bounds) {
      const northEast = bounds.getNorthEast()
      const southWest = bounds.getSouthWest()
      this.mapBounds.lat.min = southWest.lat()
      this.mapBounds.lat.max = northEast.lat() < 0 ? 180 : northEast.lat()
      this.mapBounds.lng.min = southWest.lng()
      this.mapBounds.lng.max = northEast.lng() < 0 ? 180 : northEast.lng()
    }
  }

  get minMaxBounds (): google.maps.LatLngBoundsLiteral | undefined {
    if (this.locations.length) {
      return {
        north: Math.max(...this.locations.map(location => location.latitude)),
        east: Math.max(...this.locations.map(location => location.longitude)),
        south: Math.min(...this.locations.map(location => location.latitude)),
        west: Math.min(...this.locations.map(location => location.longitude))
      }
    }
  }

  get exploreProjectsUrl (): string {
    return this.exploreUrl(this.baseExploreURL, true)
  }

  get exploreProjectsUrlNoBounds (): string {
    return this.exploreUrl(this.baseExploreURL, false)
  }

  get exploreLocationsUrl (): string {
    return this.exploreUrl(this.locationsURL, false, false)
  }

  get sortOptions (): ISelectOption[] {
    const optionArr = [
      { value: null, text: this.$gettext('Sort') },
      { value: 'title', text: this.$gettext('A-Z') },
      { value: '-title', text: this.$gettext('Z-A') },
      { value: '-created_at', text: this.$gettext('Newest') },
      { value: 'created_at', text: this.$gettext('Oldest') }
    ]
    if (this.pageSettings.isVotingList && this.pageSettings.displayCurrentVotingRank) {
      optionArr.push(...[
        { value: 'voting_rank', text: this.$gettext('Votes ascending') },
        { value: '-voting_rank', text: this.$gettext('Votes descending') }
      ])
    } else {
      optionArr.push(...[
        { value: 'balance', text: this.$gettext('Balance ascending') },
        { value: '-balance', text: this.$gettext('Balance descending') }
      ])
    }
    optionArr.push({ value: 'random', text: this.$gettext('Random') })
    return optionArr
  }

  get pageSize (): number {
    return this.pageSettings?.projectPageSize || 9
  }

  exploreUrl (rootUrl: string, includeBounds = true, paginated = true): string {
    const filtersParams: TGenericObject = {}

    if (this.filters.searchString) filtersParams.search = this.filters.searchString
    if ('hideExpired' in this.filters) {
      if (this.filters.hideExpired) filtersParams.hide_expired = this.filters.hideExpired
      if (this.filters.hideFullyFunded) filtersParams.hide_fully_funded = this.filters.hideFullyFunded
    }
    if (this.filters.categories.length) filtersParams.categories = this.filters.categories.join(',')
    if (this.filters.sortOrder) filtersParams.ordering = this.filters.sortOrder
    if (this.$route.query?.preview_token && this.$route.query?.slug) {
      filtersParams.cms_filter = this.$route.query?.slug
    } else if (this.pageSettings.slug) {
      filtersParams.cms_filter = this.pageSettings.slug
    }

    if (includeBounds) {
      if (this.mapBounds.lat.min) filtersParams.latitude_min = this.mapBounds.lat.min
      if (this.mapBounds.lat.max) filtersParams.latitude_max = this.mapBounds.lat.max
      if (this.mapBounds.lng.min) filtersParams.longitude_min = this.mapBounds.lng.min
      if (this.mapBounds.lng.max) filtersParams.longitude_max = this.mapBounds.lng.max
    }

    const filterKeys = Object.keys(filtersParams)
    const urlFilterParams = filterKeys.length ? `&${filterKeys.map(key => `${key}=${filtersParams[key]}`).join('&')}` : ''
    return paginated ? `${rootUrl}?page_size=${this.pageSize}&page=${this.currentPage}${urlFilterParams}` : `${rootUrl}?${urlFilterParams}`
  }

  toggleCategory (category: IProjectCategory): void {
    if (this.filters.categories.includes(category.slug)) {
      this.filters.categories.forEach((item, index) => {
        if (item === category.slug) {
          this.filters.categories.splice(index, 1)
        }
      })
    } else {
      this.filters.categories.push(category.slug)
    }
  }

  toggleOffAllCategories (): void {
    this.filters.categories = []
  }

  async fetchCategories (): Promise<void> {
    axios.get(this.categoriesURL).then(response => {
      this.categories = response.data
    })
  }

  async fetchLocations (): Promise<void> {
    if (!this.pageSettings.showMap) {
      return
    }
    await axios.get(this.exploreLocationsUrl).then(response => {
      this.locations = response.data
    })
  }

  async fetchItems (useBounds = true, append = false): Promise<void> {
    if (this.items.length) {
      this.loading = true
    } else {
      this.$wait.start('load items')
    }

    await axios.get(useBounds ? this.exploreProjectsUrl : this.exploreProjectsUrlNoBounds).then((response) => {
      this.itemCount = response.data.count
      this.items = append ? this.items.concat(response.data.results) : response.data.results
    })

    this.loading = false
    this.$wait.end('load items')
  }

  async loadData (): Promise<[void, void, void]> {
    const p1 = this.fetchItems()
    const p2 = this.fetchLocations()
    const p3 = this.fetchCategories()
    return Promise.all([p1, p2, p3])
  }

  async created (): Promise<void> {
    this.$wait.start('load data')

    const pageSlug = this.$route.params?.slug || 'home'
    ExploreModule.fetchPageSettings({ slug: pageSlug, previewToken: this.$route.query?.preview_token }).then(() => {
      this.pageSettings = ExploreModule.pageSettingsMap[pageSlug]

      this.filters.sortOrder = this.pageSettings.defaultSortOrder
      if ('hideExpired' in this.filters) {
        this.filters.hideExpired = this.pageSettings.hideExpiredDefault
        this.filters.hideFullyFunded = this.pageSettings.hideFullyFundedDefault
      }

      this.loadData().then(() => {
        this.$wait.end('load data')
      })
    })

    if (this.$cookies.get('cookie_rule_detail')?.includes('google-maps')) {
      this.googleMapsAccepted = true
    }

    if (document.getElementById('explore-html')) {
      this.exploreHTML = document.getElementById('explore-html').innerHTML
    }
  }
}
