
import { Vue, Component, Prop, Ref, Watch } from 'vue-property-decorator'
import { CircleStencil, Cropper, RectangleStencil } from 'vue-advanced-cropper'
import 'vue-advanced-cropper/dist/style.css'
import { IPhoto } from '@/types/photos'

@Component({
  components: {
    Cropper,
    CircleStencil,
    RectangleStencil
  }
})
export default class ImageCropper extends Vue {
  @Prop() img: File | IPhoto
  @Prop({ default: '16:9' }) format: string
  @Prop({ default: 'cropped_image.png' }) fileName: string
  @Ref() readonly cropper!: Cropper
  @Prop() readonly stencil: CircleStencil | RectangleStencil
  formatNumber = 16 / 9
  srcFile = null

  mounted (): void {
    this.setSrcFile()
    const [width, height] = this.format.split(':')
    this.formatNumber = parseFloat(width) / parseFloat(height)
  }

  @Watch("img")
  onImgChanged (): void {
    this.setSrcFile()
  }

  setSrcFile (): void {
    if (typeof this.img === 'string') {
      this.srcFile = this.img
    } else if (this.img && 'src' in this.img) {
      this.srcFile = this.img.src
    } else {
      this.srcFile = this.img
    }
  }

  get formatDict (): { width: string, height: string } {
    return {
      width: this.format.toString().split(":")[0],
      height: this.format.toString().split(":")[1]
    }
  }

  sendResult (event: Event): void {
    event.preventDefault()
    const { canvas } = (this.$refs.cropper as Cropper).getResult()
    canvas.toBlob((blob: Blob | null) => {
      if (blob) {
        const file = new File([blob], this.fileName, { type: 'image/png' })
        const fileList = [file]
        this.$emit('cropped', fileList)
      }
    }, 'image/png')
  }

  reCrop (): void {
    this.$emit("recrop", null, true)
  }

  replaceImage (): void {
    this.$emit("replace-image", this.img)
  }
}
