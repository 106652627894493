/* eslint-disable camelcase */

import {
  VuexModule, Module, Action, Mutation, getModule
} from 'vuex-module-decorators'
import store from '@/store'
import { IUserProfile } from '@/types/users'
import { API_URLS } from '@/utils/helpers'
import axios from 'axios'
import { IBalance, ISlugTitle } from '@/types/base'

export interface IUserState {
  profile: IUserProfile | null
}

@Module({ dynamic: true, store, name: 'user' })
class User extends VuexModule implements IUserState {
  profile: IUserProfile | null = JSON.parse(localStorage.getItem('profile'))

  get hasPermission () {
    return (permissionName: string) => {
      if (this.profile && this.profile.is_superuser) return true
      return this.profile && this.profile.permissions?.includes(permissionName)
    }
  }

  @Mutation
  FETCH_PROFILE (profile: IUserProfile): void {
    // If fetched the object should not be the same as the one currently in store (even if same values)
    const profileChanged = profile !== this.profile
    this.profile = profile
    if (profileChanged) {
      localStorage.setItem('profile', JSON.stringify(this.profile))
      localStorage.setItem('profileTimestamp', new Date().getTime().toString())
    }
  }

  @Action({ commit: 'FETCH_PROFILE' })
  async fetchProfile (force = false) {
    // Refresh profile if last local save is older than 5 minutes or if staff user
    let refreshProfile = true
    if (localStorage.profileTimestamp && !this.profile?.is_staff) {
      refreshProfile = force || (((new Date().getTime() - parseInt(localStorage.profileTimestamp)) / (1000 * 60)) > 5)
    }
    if (refreshProfile) return axios.get(API_URLS.USERS.PROFILE).then(res => res.data)
    return this.profile
  }

  @Mutation
  FETCH_BALANCE (balance: IBalance): void {
    this.profile.balance = balance
    localStorage.setItem('profile', JSON.stringify(this.profile))
  }

  @Action({ commit: 'FETCH_BALANCE' })
  async fetchBalance (): Promise<void> {
    return axios.get(API_URLS.USERS.BALANCE).then(res => res.data.balance)
  }

  @Mutation
  FETCH_FAVORITE_PROJECTS (favorite_projects: ISlugTitle[]): void {
    this.profile.favorite_projects = favorite_projects
    localStorage.setItem('profile', JSON.stringify(this.profile))
  }

  @Action({ commit: 'FETCH_FAVORITE_PROJECTS' })
  async fetchFavoriteProjects (): Promise<void> {
    return axios.get(API_URLS.USERS.FAVORITE_PROJECTS).then(res => res.data)
  }
}

export const UserModule = getModule(User)
