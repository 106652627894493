
import { Component, Prop, Watch, Ref, Mixins } from 'vue-property-decorator'
import { BvTableCtxObject, BvComponent } from 'bootstrap-vue'
import axios, { AxiosResponse } from 'axios'
import InputGroup from '@/components/InputGroup.vue'
import { BvTableFieldArrayWithStickColumn, IAPIFilterCtx, IInternalStatus, ITableSettings } from '@/types/base'
import { IFilter, IFilters, IOrder } from '@/types/filters'
import TableFilterDropdown from '@/components/TableFilterDropdown.vue'
import OrganizationReviewStatus from '@/components/labels/OrganizationReviewStatus.vue'
import PersistentFiltersMixin from '@/mixins/PersistentFiltersMixin'
import { PlatformFeaturesModule, IPlatformFeatures } from '@/store/modules/platformFeatures'
import ProjectStatus from '@/components/labels/ProjectStatus.vue'
import ProjectReviewStatus from '@/components/labels/ProjectReviewStatus.vue'
import ProjectContentReviewStatus from '@/components/labels/ProjectContentReviewStatus.vue'
import ProjectVotingReviewStatus from '@/components/labels/ProjectVotingReviewStatus.vue'
import ProjectFinancingStatus from '@/components/labels/ProjectFinancingStatus.vue'
import ProjectFundingAccountLabel from '@/components/labels/ProjectFundingAccountLabel.vue'
import TableOrderDropdown from '@/components/utils/TableOrderDropdown.vue'
import { IMinimalProject, IProject } from '@/types/projects'
import { addContextToUrl, API_URLS } from '@/utils/helpers'
import { ICompany } from '@/types/companies'
import { IApplicationType } from '@/types/applicationTypes'
import TableSelectAlert from '@/components/utils/TableSelectAlert.vue'
import LocaleMixin from '@/mixins/LocaleMixin'
import { IBudget, IIndividualBankAccount } from '@/types/finances'
import {
  contentStatusOptions,
  organizationTypeOptions,
  progressStatusOptions,
  reviewStatusOptions,
  statutoryPurposeOptions
} from '@/utils/filterOptions'

@Component({
  components: {
    InputGroup,
    TableFilterDropdown,
    OrganizationReviewStatus,
    ProjectStatus,
    ProjectReviewStatus,
    ProjectContentReviewStatus,
    ProjectVotingReviewStatus,
    ProjectFinancingStatus,
    ProjectFundingAccountLabel,
    TableOrderDropdown,
    TableSelectAlert
  },
  mixins: [PersistentFiltersMixin],
  name: 'project-table'
})
export default class ProjectTable extends Mixins(PersistentFiltersMixin, LocaleMixin) {
  // Table field definitions. Check https://bootstrap-vue.js.org/docs/components/table/ for more
  @Ref() readonly selectableTable!: BvComponent
  @Prop({ default: false }) archived!: boolean
  @Prop() searchString!: string
  @Prop() pageSize!: number
  @Prop() tableSettings!: ITableSettings | null
  @Prop() selectedProjects!: (IProject | IMinimalProject)[]
  projects: IProject[] = []
  projectCount = 0
  selectProjectNames: string[] = []
  orderObj: IOrder = null
  currentPage = 1
  internalStatuses: IInternalStatus[] = []
  tableContext: BvTableCtxObject | null = null
  tableLoading = false
  hoveredRowId = 0
  filterCols: { field: string; filter: string }[] = [
    { field: 'status', filter: 'projectStatusFilter' },
    { field: 'application_type', filter: 'applicationTypeFilter' },
    { field: 'review_status', filter: 'reviewStatusFilter' },
    { field: 'financing_status', filter: 'financingStatusFilter' },
    { field: 'voting_review_status', filter: 'votingReviewStatusFilter' },
    { field: 'content_review_status', filter: 'contentReviewStatusFilter' },
    { field: 'internal_status', filter: 'internalStatusFilter' },
    { field: 'organization_status', filter: 'organizationStatusFilter' },
    { field: 'funding_account', filter: 'fundingAccountFilter' },
    { field: 'supported_by_companies', filter: 'supportedByCompaniesFilter' },
    {
      field: 'supported_by_donation_limits',
      filter: 'supportedByBudgetsFilter'
    },
    { field: 'category_title', filter: 'categoryFilter' },
    { field: 'organization_type', filter: 'organizationTypeFilter' },
    { field: 'statutory_purpose_list', filter: 'statutoryPurposeListFilter' },
    { field: 'projectpromoter', filter: 'projectPromoterFilter' }
  ]

  filters: IFilters = {
    // Define filter options. Those that we see in the dropdowns
    projectStatusFilter: {
      filterName: 'status_named',
      selected: [],
      options: progressStatusOptions(this.$language.current)
    },
    organizationStatusFilter: {
      filterName: 'organization_status_named',
      selected: [],
      options: {
        public: this.$gettext('Public'),
        rejected: this.$gettext('Rejected'),
        accepted: this.$gettext('Accepted'),
        needs_review: this.$gettext('Needs review'),
        noe_expired: this.$gettext('Proof expired')
      }
    },
    applicationTypeFilter: {
      filterName: 'application_type',
      selected: [],
      options: {}
    },
    reviewStatusFilter: {
      filterName: 'review_status_named',
      selected: [],
      options: reviewStatusOptions(this.$language.current)
    },
    financingStatusFilter: {
      filterName: 'financing_status',
      selected: [],
      options: {
        fully_funded: this.$gettext('Fully funded'),
        expired: this.$gettext('Expired'),
        can_receive_fundings: this.$gettext('In financing'),
        is_banned: this.$gettext('Banned')
      }
    },
    contentReviewStatusFilter: {
      filterName: 'content_review_status_named',
      selected: [],
      options: contentStatusOptions(this.$language.current)
    },
    votingReviewStatusFilter: {
      filterName: 'voting_review_status_named',
      selected: [],
      options: {
        needs_review: this.$gettext('Needs review'),
        public: this.$gettext('Public'),
        rejected: this.$gettext('Rejected')
      }
    },
    fundingAccountFilter: {
      filterName: 'funding_account_named',
      selected: [],
      options: {}
    },
    internalStatusFilter: {
      filterName: 'internal_status__title',
      selected: []
    },
    supportedByCompaniesFilter: {
      filterName: 'supported_by_companies',
      selected: [],
      options: {}
    },
    supportedByBudgetsFilter: {
      filterName: 'supported_by_donation_limits',
      selected: [],
      options: {}
    },
    categoryFilter: {
      filterName: 'category_slug',
      selected: [],
      options: {}
    },
    organizationTypeFilter: {
      filterName: 'organization_type_named',
      selected: [],
      options: organizationTypeOptions(this.$language.current)
    },
    statutoryPurposeListFilter: {
      filterName: 'statutory_purpose_list',
      selected: [],
      options: statutoryPurposeOptions(this.$language.current)
    },
    projectPromoterFilter: {
      filterName: 'organization_slug',
      selected: [],
      options: {}
    }
  }

  @Watch('pageSize')
  onPageSizeChanged(): void {
    this.loadProjects()
  }

  @Watch('currentPage')
  onCurrentPageChanged(): void {
    this.loadProjects()
  }

  @Watch('searchString')
  onSearchStringChanged(): void {
    this.loadProjects()
  }

  get platformFeatures(): IPlatformFeatures | null {
    return PlatformFeaturesModule.platformFeatures
  }

  get sortDesc(): boolean | undefined {
    if (this.orderObj) return this.orderObj.sortDesc
    return this.tableContext ? this.tableContext.sortDesc : undefined
  }

  get sortBy(): string | undefined {
    if (this.orderObj) return this.orderObj.sortBy
    return this.tableContext ? this.tableContext.sortBy : undefined
  }

  get urlContext(): IAPIFilterCtx {
    return {
      sortBy: this.sortBy,
      sortDesc: this.sortDesc,
      search: this.searchString,
      filters: this.activeFilters,
      page: this.currentPage,
      pageSize: this.pageSize,
      archived: this.archived
    }
  }

  getProjectCol(project: IProject): string {
    return `<a href="/site-admin/project/${project.slug}/update/">${project.title}</a>`
  }

  getInternalStatusCol(project: IProject): string {
    return project.internal_status ? project.internal_status.label : '-'
  }

  getOrganizationCol(project: IProject): string {
    return `<a href="/site-admin/organization/${project.organization.slug}/update/">${project.organization.title}</a>`
  }

  getApplicationTypeCol(project: IProject): string {
    return `<span class="badge badge-info label-fundingtype-${
      project.application_type ? project.application_type.slug : 'no-application_type'
    }">${project.application_type ? project.application_type.title : '-'}</span>`
  }

  getPrivateDonationsCol(project: IProject): string {
    if (project.private_donation_amount.in_cents > 0) {
      return `<a
        href="${API_URLS.PRIVATE_DONATIONS.EXPORT('project_slug=' + project.slug + '&status_named=verified')}" 
        target="_blank">
          ${this.toCurrency(project.private_donation_amount.in_currency)}
        </a>`
    }
    return this.toCurrency(project.private_donation_amount.in_currency)
  }

  sortingChanged(ctx: BvTableCtxObject): void {
    this.tableContext = ctx
    this.orderObj = null
    this.loadProjects()
  }

  rowHovered(row: IProject): void {
    if (row.id) {
      this.hoveredRowId = row.id
    }
  }

  applyFilterAndReload(filter: IFilter): void {
    this.applyFilter(filter)
    this.reloadProjects()
  }

  applyOrderAndReload(orderObj: IOrder): void {
    this.orderObj = orderObj
    this.reloadProjects()
  }

  resetFiltersAndReload(): void {
    this.resetFilters()
    this.reloadProjects()
  }

  get emptyText(): string {
    return this.$gettext('No projects found')
  }

  get searchPlaceholder(): string {
    return this.$gettext('Search projects')
  }

  get allVisibleItemsSelected(): boolean {
    return this.projects.length <= this.selectedProjects.length
  }

  get availableFields(): BvTableFieldArrayWithStickColumn {
    const _availableFields = this.fields.slice()
    if (this.tableSettings) {
      const fieldKeys = Object.keys(this.tableSettings.table_fields)
      const additionalFields = this.tableSettings.table_fields.additional_fields
      if (additionalFields) {
        for (const additionalStepKey of Object.keys(additionalFields)) {
          const additionalStep = this.tableSettings.table_fields.additional_fields[additionalStepKey]
          for (const additionalFieldKey of Object.keys(additionalStep)) {
            fieldKeys.push(additionalFieldKey)
          }
        }
      }
      const displayedFields = fieldKeys.filter(
        (field) => this.tableSettings && !this.tableSettings.hidden_fields.includes(field)
      )
      displayedFields.unshift('selected', 'index')
      _availableFields.unshift(
        { key: 'selected', label: 'Select All', sortable: false },
        { key: 'index', label: this.$gettext('#') }
      )
      return _availableFields.filter((field) => {
        const fieldName = typeof field === 'string' ? field : field.key
        return displayedFields.includes(fieldName)
      })
    }
    return []
  }

  get availableFilterCols(): { field: string; filter: string }[] {
    if (this.tableSettings) {
      const fieldKeys = Object.keys(this.tableSettings.table_fields)
      return this.filterCols.filter((filterCol) => {
        return (
          this.tableSettings &&
          !this.tableSettings.hidden_fields.includes(filterCol.field) &&
          fieldKeys.includes(filterCol.field)
        )
      })
    }
    return []
  }

  async loadProjects(): Promise<void> {
    this.tableLoading = true
    await axios.get(addContextToUrl(API_URLS.PROJECTS.LIST, this.urlContext)).then((response) => {
      this.projects = response.data.results
      this.projectCount = response.data.count
      this.$emit('total-count-update', this.projectCount)
    })
    this.tableLoading = false
  }

  async reloadProjects(): Promise<void> {
    this.tableLoading = true
    this.currentPage = 1
    this.loadProjects()
  }

  async loadAllFilteredProjectNames(): Promise<AxiosResponse<IMinimalProject[]>> {
    return axios.get(addContextToUrl(API_URLS.PROJECTS.MINIMAL_LIST, this.urlContext))
  }

  get allProjectsAPIURL() {
    return addContextToUrl(API_URLS.PROJECTS.MINIMAL_LIST, this.urlContext)
  }

  async loadInitialProjects(): Promise<void> {
    this.$wait.start('fetch projects')
    await Promise.all([this.loadProjects(), this.loadInternalStatuses()])
    // If we have internal statuses, create dict of them, add them to filter options and add column to table
    if (this.internalStatuses.length) {
      this.filters.internalStatusFilter.options = this.internalStatuses.reduce(
        (obj, item) => Object.assign(obj, { [item.title]: item.title }),
        {}
      )
    }
    // Filter name for content review status is different than that of provided **FIXED
    if (this.projects.length && Object.prototype.hasOwnProperty.call(this.projects[0], 'content_review_status')) {
      this.filters.organizationStatusFilter.options = Object.assign(this.filters.organizationStatusFilter.options, {
        content_accepted: this.$gettext('Content accepted'),
        content_rejected: this.$gettext('Content rejected'),
        nonprofit: this.$gettext('nonprofit'),
        not_nonprofit: this.$gettext('Not nonprofit')
      })
    }
    this.$wait.end('fetch projects')
  }

  async loadInternalStatuses(): Promise<void> {
    const url = `${API_URLS.PROJECTS.INTERNAL_STATUS}?page_size=100`
    return axios.get(url).then((response) => {
      this.internalStatuses = response.data.results
    })
  }

  async loadBankAccounts(): Promise<void> {
    axios.get(API_URLS.INDIVIDUAL_BANK_ACCOUNTS.LIST).then((response) => {
      const individualBankAccounts = response.data.results as IIndividualBankAccount[]
      for (const bankAccount of individualBankAccounts) {
        this.filters.fundingAccountFilter.options[bankAccount.slug] = bankAccount.title
      }
    })
  }

  async loadCategories(): Promise<void> {
    axios.get(API_URLS.FUNDING_STATISTICS.CATEGORY).then((response) => {
      for (const category of response.data) {
        this.filters.categoryFilter.options[category.slug] = category.title
      }
    })
  }

  async loadSupportedByOptions(): Promise<void> {
    axios.get(API_URLS.COMPANIES.DONATING_COMPANIES).then((response) => {
      const companies = response.data as ICompany[]
      for (const company of companies) {
        this.filters.supportedByCompaniesFilter.options[company.slug] = company.title
      }
    })
    axios.get(API_URLS.DONATION_LIMITS.COMPANIES).then((response) => {
      const budgets = response.data.results as IBudget[]
      for (const budget of budgets) {
        this.filters.supportedByBudgetsFilter.options[budget.slug] = budget.title
      }
    })
  }

  async loadApplicationTypes(): Promise<void> {
    axios.get(API_URLS.APPLICATION_TYPES.MINIMAL_LIST).then((response) => {
      for (const applicationType of response.data as IApplicationType[]) {
        this.filters.applicationTypeFilter.options[applicationType.slug] = applicationType.title
      }
    })
  }

  async loadOrganizationsOptions(): Promise<void> {
    axios
      .get(
        addContextToUrl(API_URLS.ORGANIZATIONS.MINIMAL_LIST, {
          sortBy: 'title'
        })
      )
      .then((response) => {
        for (const organization of response.data as ICompany[]) {
          this.filters.projectPromoterFilter.options[organization.slug] = organization.title
        }
      })
  }

  toggleCheckAll(val: boolean | string): void {
    if (val) this.selectableTable.selectAllRows()
    else this.selectableTable.clearSelected()
  }

  onRowSelected(selectedItems: IProject[]): void {
    this.$emit('update:selectedProjects', selectedItems)
  }

  toggleSelect(rowIndex: number): void {
    if (!this.selectableTable.isRowSelected(rowIndex)) {
      this.selectableTable.selectRow(rowIndex)
    } else {
      this.selectableTable.unselectRow(rowIndex)
    }
  }

  async created(): Promise<void> {
    await PlatformFeaturesModule.fetchPlatformFeatures()
    this.loadInitialProjects()
    this.loadBankAccounts()
    this.loadSupportedByOptions()
    this.loadApplicationTypes()
    this.loadCategories()
    this.loadOrganizationsOptions()
  }
}
