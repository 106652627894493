
import { Component, Prop, Vue } from 'vue-property-decorator'
import { IOrganization, IProject } from '@/types/projects'

@Component({
  name: 'project-voting-review-status'
})
export default class ProjectVotingReviewStatus extends Vue {
  @Prop() project!: IProject | IOrganization

  get label (): string {
    let labelHtml = '-'
    const status = this.project.voting_review_status
    if (status) {
      if (status.choice === 2) labelHtml = `<span class="badge badge-success">${status.display}</span>` // public
      else if (status.choice === 3) labelHtml = `<span class="badge badge-danger">${status.display}</span>` // rejected
      else if (status.choice === 4) labelHtml = `<span class="badge badge-success">${status.display}</span>` // accepted
    }
    if (this.project.needs_voting_review) {
      const needsReviewLabel = `<span class="badge badge-warning">${this.$gettext('Needs review')}</span>`
      if (labelHtml.length > 1) labelHtml += needsReviewLabel
      else labelHtml = needsReviewLabel
    }
    return labelHtml
  }
}
