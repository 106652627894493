

import { Component, Prop, Vue } from 'vue-property-decorator'
import { v4 as uuid4 } from 'uuid'

@Component({
  name: 'labeled-tooltip'
})
export default class LabeledTooltip extends Vue {
  @Prop({ default: () => uuid4() }) id!: string
  @Prop({ default: '' }) title!: string
  @Prop({ default: '' }) description!: string
}
