

import { IStep } from '@/types/base'
import { Component, Prop, Vue } from 'vue-property-decorator'

@Component({
  name: 'sf-steps'
})
export default class SfSteps extends Vue {
  @Prop({ default: () => [] }) value!: IStep[]

  changeStep (step: IStep, initial?: boolean): void {
    if (step.disabled || step.locked) return
    const otherSteps = this.value.filter(_step => _step.value !== step.value)
    for (const _step of otherSteps) {
      _step.active = false
    }
    step.active = true
    if (!initial) this.$router.push({ query: { 'v-step': step.value } })
  }

  getActiveStepIndex (): number {
    let activeStepIdx = 0
    for (let i = 0; i < this.value.length; i++) {
      if (this.value[i].active) {
        activeStepIdx = i
        break
      }
    }
    return activeStepIdx
  }

  nextStep (): void {
    const activeStepIdx = this.getActiveStepIndex()
    for (let i = activeStepIdx + 1; i < this.value.length; i++) {
      const nextStep = this.value[i]
      if (nextStep && !(nextStep.disabled || nextStep.locked)) {
        this.changeStep(nextStep)
        break
      }
    }
  }

  previousStep (): void {
    const activeStepIdx = this.getActiveStepIndex()
    for (let i = activeStepIdx - 1; i >= 0; i--) {
      const nextStep = this.value[i]
      if (nextStep && !(nextStep.disabled || nextStep.locked)) {
        this.changeStep(nextStep)
        break
      }
    }
  }

  created (): void {
    if (this.$route.query['v-step']) {
      const validSteps = this.value.filter(step => step.value === this.$route.query['v-step'])
      if (validSteps.length === 1) this.changeStep(validSteps[0], true)
    }
  }
}
