import axios from 'axios'
import Moment from 'moment'
import 'moment/locale/de'
import Vue from "vue"
import VueCookies from 'vue-cookies'
import GetTextPlugin from 'vue-gettext'
import VueMoment from 'vue-moment'
import VueWait from 'vue-wait'

import { BootstrapVue, BootstrapVueIcons } from 'bootstrap-vue'

import translations from '@/translations/translations.json'
import { getLocale } from '@/utils/helpers'

export function setUpVue () {
  Vue.config.productionTip = false
  Vue.use(VueWait)
  Vue.use(VueCookies)
  Vue.use(GetTextPlugin, {
    availableLanguages: {
      en_US: 'American English',
      du_DE: 'German (Du)',
      sie_DE: 'German (Sie)'
    },
    defaultLanguage: getLocale(),
    translations
  })
  Vue.use(BootstrapVue)
  Vue.use(BootstrapVueIcons)

  Moment.locale()
  Vue.use(VueMoment, { moment: Moment })

  axios.defaults.headers.common = {
    "X-CSRFToken": Vue.$cookies.get('csrftoken')
  }
  return Vue
}
