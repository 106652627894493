
import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import { BDropdown } from 'bootstrap-vue'
import { IFilterOption } from '@/types/filters'

@Component({
  name: 'table-filter-dropdown'
})
export default class TableFilterDropdown extends Vue {
  @Prop() label!: string
  @Prop() filterName!: string
  @Prop() fieldName!: string
  @Prop({ default: () => [] }) options!: IFilterOption
  @Prop({ default: () => [] }) selected!: string[]
  @Prop({ default: '' }) menuClass!: string
  @Prop({ default: false }) searchable!: boolean
  $refs!: { dropdown: BDropdown }
  internalSelected: string[] = []
  searchString = ''

  @Watch('selected')
  onSelectedChanged (val: string[]): void {
    // Only update if those two diverge, or we will end up in an infinite loop
    if (!(
      val.length === this.internalSelected.length &&
      this.internalSelected.every((selectVal, idx) => selectVal === val[idx])
    )) {
      this.internalSelected = this.selected.slice()
    }
  }

  @Watch('internalSelected')
  onInternalSelectedChanged (val: string[]): void {
    this.$emit('update:selected', val)
  }

  get filteredOptions (): IFilterOption {
    if (this.searchString) {
      return Object.keys(this.options).reduce((newObj, key) => {
        if (this.options[key].toLowerCase().includes(this.searchString.toLowerCase())) {
          newObj[key] = this.options[key]
        }
        return newObj
      }, {})
    }
    return this.options
  }

  get showSearch (): boolean {
    return this.searchable || Object.keys(this.options).length >= 15
  }

  get hasSelected (): boolean {
    return this.internalSelected.length > 0
  }

  get menuClasses (): string {
    return `dropdown-menu-search ${this.menuClass}`
  }

  applyFilters (): void {
    this.$refs.dropdown.hide(true)
    this.$emit('on-apply', { filterName: this.filterName, selected: this.selected, fieldName: this.fieldName })
  }

  mounted (): void {
    this.internalSelected = this.selected.slice()
  }
}
