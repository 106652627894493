
import { Component, Prop, Emit, Watch } from 'vue-property-decorator'
import FieldValidationMixin from '../../FieldValidationMixin.vue'
@Component
export default class CharField extends FieldValidationMixin {
  @Prop({ default: null }) label!: string
  @Prop({ default: '' }) value!: string
  @Prop({ default: '' }) error!: string
  @Prop({ default: false }) required!: boolean
  @Prop({ default: false }) readOnly!: boolean
  @Prop({ default: null }) charLimit!: number
  @Prop({ default: false }) charLimitInfo!: boolean

  displayError = ''
  isFocused = false

  @Watch('error')
  onErrorChange () {
    this.displayError = this.error
  }

  mounted () {
    this.displayError = this.error
  }

  @Emit('input')
  updateValue(newValue: InputEvent) {
    const input = newValue.target as HTMLInputElement
    return input.value
  }

  get state (): boolean | null {
    return this.displayError ? false : null
  }

  onFocus () {
    this.isFocused = true
  }

  onBlur (event: InputEvent) {
    this.isFocused = false
    const input = event.target as HTMLInputElement
    this.$emit('blur', input.value)
  }
}
