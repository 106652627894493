

import { Component, Prop, Vue } from 'vue-property-decorator'
import IconButton from './IconButton.vue'

@Component({
  components: {
    IconButton
  }
})
export default class DuplicateButton extends Vue {
  @Prop({ default: () => Vue.prototype.$gettext("Duplicate") }) tooltip!: string
  @Prop({ default: false }) disabled!: boolean

  onButtonClicked (): void {
    this.$emit("btn-clicked")
  }
}
