
import { Component, Prop, Vue } from 'vue-property-decorator'

@Component({
  inheritAttrs: false
})
export default class InputGroup extends Vue {
  @Prop() icon!: string
  @Prop({ default: '' }) value!: string
  @Prop() placeholder?: string
  @Prop() onChange?: string
  @Prop({ default: 0 }) debounce!: number

  get valueModel (): string {
    return this.value
  }

  set valueModel (value: string) {
    this.$emit('input', value)
  }

  get iconClass (): string {
    return 'fa fa-' + this.icon
  }
}
