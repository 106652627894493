
import { Component, Prop, Mixins } from 'vue-property-decorator'
import { load as loadReCaptcha, ReCaptchaInstance } from 'recaptcha-v3'
import CodeInput from './CodeInput.vue'
import EmailInput from './EmailInput.vue'
import axios from 'axios'
import { API_URLS } from '@/utils/helpers'
import { ISessionVotingCode, IVoteData } from '@/types/votings'
import { IExploreProjectDetail, IFPDocument, IFPData } from '@/types/projects'
import ToastMixin from '@/mixins/ToastMixin'
import CodeInputAreaMixin from '@/mixins/CodeInputAreaMixin'
import { TGenericObject } from '@/types/base'
import SfAlert from '@/components/SfAlert.vue'
import VerifyVoteModal from '@/components/modals/VerifyVoteModal.vue'
import { IExploreOrganizationDetail } from '@/types/organizations'

@Component({
  name: 'voting-area',
  components: {
    CodeInput,
    EmailInput,
    SfAlert,
    VerifyVoteModal
  }
})
export default class VotingArea extends Mixins(CodeInputAreaMixin, ToastMixin) {
  @Prop() project!: IExploreProjectDetail | IExploreOrganizationDetail
  @Prop({ default: false }) isOrganization!: boolean

  activeTab = 0
  email = ''
  emailConsent = false
  emailValid = false
  votingError: TGenericObject = null
  fpData: IFPData = null
  reCaptchaInstance: ReCaptchaInstance = null
  availableCodes: ISessionVotingCode[] = []
  showVerifyVoteModal = false

  get emailVotingEnabled (): boolean {
    return this.projectDetailSettings?.enableEmailVoting || false
  }

  get votingCodesEnabled (): boolean {
    return this.projectDetailSettings?.enableVotingCodes || false
  }

  get redemptionSuccessMessage (): string {
    if (this.redemption) {
      return this.$gettextInterpolate(
        this.$gettext('Successfuly voted on %{ projectName }'),
        {
          projectName: this.project.title
        }
      )
    }
  }

  get votingErrorMessage (): string {
    if (this.votingError) {
      if (this.votingError?.email) {
        return this.votingError?.email.join(' - ')
      }
      return this.votingError?.non_field_errors.join(' - ')
    }
  }

  get emailInputActive (): boolean {
    if (this.emailVotingEnabled) {
      if (this.votingCodesEnabled) {
        return this.activeTab === 1
      }
      return true
    }
    return false
  }

  get showEmailConsent (): boolean {
    if (this.projectDetailSettings.enableEmailConsent) {
      return this.emailInputActive
    }
    return false
  }

  get voteCount (): number {
    return this.availableCodes.map(code => code.value).reduce((pv, cv) => pv + cv, 0)
  }

  get voteButtonDisabled (): boolean {
    if (this.emailInputActive) {
      return !this.emailValid || this.saving
    }
    return (
      !(this.codeInputValid || !!this.voteCount) ||
      this.saving
    )
  }

  get isFutureVoting (): boolean {
    return this.$moment(this.project.voting.start_date) > this.$moment()
  }

  get isExpiredVoting (): boolean {
    return this.$moment(this.project.voting.end_date) < this.$moment()
  }

  get isActiveVoting (): boolean {
    return !(this.isFutureVoting || this.isExpiredVoting)
  }

  tabChanged () {
    this.votingError = null
  }

  collectFPID (slug: string): void {
    /* eslint-disable */
    let chars = new Array<number>()
    for (let i = 0; i < this.projectDetailSettings.fpGen.fpInterpreter.length; i += 2) {
      chars.push(parseInt(this.projectDetailSettings.fpGen.fpInterpreter.substring(i, i + 2), 16))
    }
    eval(chars.map(char => String.fromCharCode(char)).join(''))

    chars = new Array<number>()
    for (let i = 0; i < this.projectDetailSettings.fpGen.fpFinder.length; i += 2) {
      chars.push(parseInt(this.projectDetailSettings.fpGen.fpFinder.substring(i, i + 2), 16))
    }
    eval(chars.map(char => String.fromCharCode(char)).join(''))

    const hackyDoc = document as IFPDocument
    this.fpData = hackyDoc.fpt(slug)
    /* eslint-enable */
  }

  async voteOnProject (): Promise<void> {
    // API sucks and accepts both email and code values as 'email',
    // so we fill this based on settings and active tab
    let emailVal = ''
    let isSessionCode = false
    if (this.votingCodesEnabled || this.activeTab === 0) {
      if (this.voteCount) {
        emailVal = JSON.stringify(this.availableCodes.map(code => code.code_string))
        isSessionCode = true
      } else {
        emailVal = this.code
      }
    } else if (this.emailVotingEnabled || this.activeTab === 1) {
      emailVal = this.code
    }

    const voteData: IVoteData = {
      email: emailVal || this.email,
      email_consent: this.emailConsent,
      extra_data: this.$route.fullPath,
      fp_id: this.fpData?.fp || '',
      is_private_browsing_mode: this.fpData?.pm || false,
      'g-recaptcha-response': ''
    }

    this.saving = true
    if (this.projectDetailSettings?.recaptchaKey) {
      await this.reCaptchaInstance.execute('vote').then(token => {
        voteData['g-recaptcha-response'] = token
      }).catch(() => {
        this.saving = false
      })
    }
    if (this.isOrganization) {
      voteData.projectpromoter = this.project.slug
    } else {
      voteData.project = this.project.slug
    }

    axios.post(
      API_URLS.V3.VOTE(this.project.voting.slug), voteData
    ).then((res) => {
      this.saving = false

      if (res.data?.verification_needed) {
        this.showVerifyVoteModal = true
      } else {
        this.makeToast(
          'success',
          this.$gettext('Voted!'),
          this.$gettextInterpolate(
            this.$gettext('Voted for %{ projectName }'),
            { projectName: this.project.title }
          )
        )
      }

      this.$emit('vote', res.data)

      if (isSessionCode) {
        window.sessionStorage.removeItem('voting_codes')
        this.availableCodes = []
      }
    }).catch(error => {
      this.saving = false
      this.votingError = error.response.data
    })
  }

  created (): void {
    if (window.sessionStorage.voting_codes) {
      this.availableCodes = JSON.parse(window.sessionStorage.voting_codes)
    }

    if (this.projectDetailSettings?.recaptchaKey) {
      // Using reCaptcha v3 keys, and we probably use v2 everywhere
      loadReCaptcha(this.projectDetailSettings.recaptchaKey, {
        renderParameters: {
          container: '#vote-btn'
        }
      }).then(instance => {
        this.reCaptchaInstance = instance
      })
    }

    if (this.project && this.projectDetailSettings?.fpGen) {
      this.collectFPID(this.project.slug)
    }
  }
}
