
import { Component, Mixins, Prop } from 'vue-property-decorator'
import CodeInput from './CodeInput.vue'
import axios from 'axios'
import { addContextToUrl, API_URLS } from '@/utils/helpers'
import CodeInputAreaMixin from '@/mixins/CodeInputAreaMixin'
import UserMixin from '@/mixins/UserMixin'
import { IExploreProjectDetail } from '@/types/projects'
import ToastMixin from '@/mixins/ToastMixin'

@Component({
  name: 'coin-code-input-area',
  components: {
    CodeInput
  }
})
export default class CoinCodeInputArea extends Mixins(CodeInputAreaMixin, UserMixin, ToastMixin) {
  @Prop() project!: IExploreProjectDetail

  get redemptionSuccessMessage (): string {
    if (this.redemption && this.currencyDisplay) {
      return this.$gettextInterpolate(
        this.$gettext('Successfuly redeemed code worth %{ value }'),
        {
          value: this.formatCurrency(this.currencyDisplay.useCurrency ? this.toLocalisedDecimal(this.redemption.cents / 100) : this.redemption.coins)
        }
      )
    }
  }

  get redemptionErrorMessage (): string {
    if (this.redemptionError) {
      return this.redemptionError?.code_string.join(' - ')
    }
  }

  formatCurrency (val: number): string {
    const displayName = this.currencyDisplay.useCurrency ? this.currencyDisplay.currencySymbol : this.currencyDisplay.coinNamePlural
    return `${val} ${displayName}`
  }

  redeemCode (): void {
    this.saving = true
    this.redemption = null
    if (!this.userProfile.is_anonymous) {
      axios.defaults.headers.common['X-CSRFToken'] = this.$cookies.get('csrftoken')
    }
    const redeemUrl = addContextToUrl(API_URLS.COINS.CODES.REDEEM, { extraParams: { 'project-slug': this.$route.params.projectSlug } })
    const data = { code_string: this.code.replaceAll(/[^\w\s]/gi, '') }

    axios.post(redeemUrl, data).then((res) => {
      this.saving = false
      this.redemption = res.data
      this.redemptionError = null
      if (this.userProfile.is_anonymous) {
        this.fetchUserProfile(true)
        this.$emit('anonRedemeed')
      } else {
        this.$emit('nonAnonRedemeed')
      }
    }).catch(error => {
      this.saving = false
      this.redemption = null
      this.redemptionError = error.response.data
      if ('response' in error && error.response.data && error.response.data.code_string) {
        this.makeToast('danger', this.$gettext('Error'), error.response.data.code_string)
      } else {
        this.makeToast('danger', this.$gettext('Error'), `${this.$gettext('Failed to redeem code')}`)
      }
    })
  }
}
