
import { Prop, Component, Vue } from 'vue-property-decorator'
import { IExploreProjectDetail } from '@/types/projects'
@Component({
  name: 'info-area'
})
export default class InfoArea extends Vue {
  @Prop() project!: IExploreProjectDetail

  // Get localised date
  formatDate (dateStr: string): string {
    if (!dateStr) return '-'
    return this.$moment(dateStr).format('LL')
  }
}
