
import { Component, Prop, Vue } from 'vue-property-decorator'

@Component({
  name: 'favourite-added-modal'
})

export default class FavouriteAddedModal extends Vue {
    @Prop({ default: false }) value!: boolean

    get isOpenModal (): boolean {
      return this.value
    }

    set isOpenModal (value: boolean) {
      this.$emit('input', value)
    }

    closeModal () {
      this.isOpenModal = false
      location.reload()
    }
}
