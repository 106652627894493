
import { Component, Prop, Vue } from 'vue-property-decorator'
import { IOrganization } from '@/types/projects'

@Component({
  name: 'organization-review-status'
})
export default class OrganizationReviewStatus extends Vue {
  @Prop() organization!: IOrganization

  get label (): string {
    let labelHtml = '-'
    const status = this.organization.nonprofit_review_status
    if (status) {
      if (status.choice === 2) labelHtml = `<span class="badge badge-success">${status.display}</span>` // nonprofit
      else if (status.choice === 3) labelHtml = `<span class="badge badge-danger">${status.display}</span>` // not nonprofit
    }
    return labelHtml
  }
}
