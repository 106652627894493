
import { ICoFunding } from '@/types/finances'
import { Component, Prop, Vue } from 'vue-property-decorator'

@Component({
  name: 'voting-info'
})
export default class VotingInfo extends Vue {
  @Prop() coFundings!: ICoFunding[]
}
