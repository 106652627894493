
import { Component, Prop, Emit, Watch, Vue } from 'vue-property-decorator'

@Component
export default class SearchField extends Vue {
  @Prop({ default: null }) label!: string
  @Prop({ default: '' }) value!: string
  @Prop({ default: '' }) error!: string
  @Prop({ default: false }) required!: boolean
  @Prop({ default: false }) readOnly!: boolean
  @Prop({ default: '' }) placeholder!: string

  displayError = ''

  @Watch('error')
  onErrorChange () {
    this.displayError = this.error
  }

  mounted () {
    this.displayError = this.error
  }

  @Emit('update')
  updateValue(newValue: InputEvent) {
    const input = newValue.target as HTMLInputElement
    return input.value
  }

  get state (): boolean | null {
    return this.displayError ? false : null
  }

  onBlur () {
    this.$emit('blur')
  }
}
