
import { Component, Prop, Watch, Ref, Mixins } from 'vue-property-decorator'
import axios from 'axios'
import { BvTableCtxObject, BvComponent } from 'bootstrap-vue'
import InputGroup from '@/components/InputGroup.vue'
import { IInternalStatus, ITableSettings } from '@/types/base'
import { IFilter, IFilters } from '@/types/filters'
import TableFilterDropdown from '@/components/TableFilterDropdown.vue'
import OrganizationStatus from '@/components/labels/OrganizationStatus.vue'
import OrganizationProofStatus from '@/components/labels/OrganizationProofStatus.vue'
import OrganizationReviewStatus from '@/components/labels/OrganizationReviewStatus.vue'
import OrganizationContentReviewStatus from '@/components/labels/OrganizationContentReviewStatus.vue'
import OrganizationNonProfitReviewStatus from '@/components/labels/OrganizationNonProfitReviewStatus.vue'
import ProjectVotingReviewStatus from '@/components/labels/ProjectVotingReviewStatus.vue'
import { IOrganization, IProject } from '@/types/projects'
import {
  contentStatusOptions,
  organizationTypeOptions,
  progressStatusOptions,
  reviewStatusOptions,
  statutoryPurposeOptions
} from '@/utils/filterOptions'
import { addContextToUrl, API_URLS, SITE_URLS } from '@/utils/helpers'
import PersistentFiltersMixin from '@/mixins/PersistentFiltersMixin'

@Component({
  components: {
    InputGroup,
    OrganizationStatus,
    OrganizationReviewStatus,
    OrganizationContentReviewStatus,
    OrganizationNonProfitReviewStatus,
    OrganizationProofStatus,
    ProjectVotingReviewStatus,
    TableFilterDropdown
  },
  mixins: [PersistentFiltersMixin],
  name: 'organizations-table'
})
export default class OrganizationsTable extends Mixins(PersistentFiltersMixin) {
  // Table field definitions. Check https://bootstrap-vue.js.org/docs/components/table/ for more
  @Ref() readonly selectableTable!: BvComponent
  @Prop({ default: false }) archived!: boolean
  @Prop() searchString!: string
  @Prop() pageSize!: number
  @Prop() tableSettings!: ITableSettings | null
  organizations: IOrganization[] = []
  internalStatuses: IInternalStatus[] = []
  totalCount = 0
  currentPage = 1
  tableContext: BvTableCtxObject | null = null
  tableLoading = false
  hoveredRowId = 0
  selectedOrganizations: IOrganization[] = []
  filterCols: { field: string; filter: string }[] = [
    { field: 'status', filter: 'organizationStatusFilter' },
    { field: 'review_status', filter: 'reviewStatusFilter' },
    { field: 'voting_review_status', filter: 'votingReviewStatusFilter' },
    { field: 'content_review_status', filter: 'contentReviewStatusFilter' },
    { field: 'nonprofit_review_status', filter: 'nonprofitReviewStatusFilter' },
    { field: 'proof', filter: 'proofFilter' },
    { field: 'internal_status', filter: 'internalStatusFilter' },
    { field: 'organization_type', filter: 'organizationTypeFilter' },
    { field: 'statutory_purpose_list', filter: 'statutoryPurposeListFilter' }
  ]

  filters: IFilters = {
    organizationStatusFilter: {
      filterName: 'status_named',
      selected: [],
      options: progressStatusOptions(this.$language.current)
    },
    reviewStatusFilter: {
      filterName: 'review_status_named',
      selected: [],
      options: reviewStatusOptions(this.$language.current)
    },
    votingReviewStatusFilter: {
      filterName: 'voting_review_status_named',
      selected: [],
      options: reviewStatusOptions(this.$language.current)
    },
    contentReviewStatusFilter: {
      filterName: 'content_review_status_named',
      selected: [],
      options: contentStatusOptions(this.$language.current)
    },
    nonprofitReviewStatusFilter: {
      filterName: 'nonprofit_review_status_named',
      selected: [],
      options: {
        accepted: this.$gettext('Nonprofit'),
        rejected: this.$gettext('Not nonprofit')
      }
    },
    proofFilter: {
      filterName: 'proof_status_named',
      selected: [],
      options: {
        active: this.$gettext('Valid'),
        expired: this.$gettext('Expired')
      }
    },
    internalStatusFilter: {
      filterName: 'internal_status__title',
      selected: []
    },
    organizationTypeFilter: {
      filterName: 'organization_type_named',
      selected: [],
      options: organizationTypeOptions(this.$language.current)
    },
    statutoryPurposeListFilter: {
      filterName: 'statutory_purpose_list',
      selected: [],
      options: statutoryPurposeOptions(this.$language.current)
    }
  }

  @Watch('pageSize')
  onPageSizeChanged() {
    this.loadOrganizations()
  }

  @Watch('currentPage')
  onCurrentPageChanged() {
    if (!this.tableLoading) this.loadOrganizations()
  }

  @Watch('searchString')
  onSearchStringChanged() {
    this.loadOrganizations()
  }

  getOrganizationCol(organization: IProject): string {
    return `<a href="${SITE_URLS.SITE_ADMIN.ORGANIZATIONS.UPDATE(organization.slug)}">${organization.title}</a>`
  }

  getFundingTypeCol(project: IProject): string {
    return `<span class="badge badge-info">${project.funding_type ? project.funding_type.display : '-'}</span>`
  }

  sortingChanged(ctx: BvTableCtxObject) {
    this.tableContext = ctx
    this.loadOrganizations()
  }

  rowHovered(row: IProject) {
    if (row.id) {
      this.hoveredRowId = row.id
    }
  }

  applyFilterAndReload(filter: IFilter): void {
    this.applyFilter(filter)
    this.loadOrganizations()
  }

  resetFiltersAndReload(): void {
    this.resetFilters()
    this.loadOrganizations()
  }

  getInternalStatusCol(organization: IOrganization): string {
    return organization.internal_status ? organization.internal_status.label : '-'
  }

  get searchPlaceholder() {
    return this.$gettext('Search organizations')
  }

  get availableFields() {
    const _availableFields = this.fields.slice()
    if (this.tableSettings) {
      const fieldKeys = Object.keys(this.tableSettings.table_fields)
      const additionalFields = this.tableSettings.table_fields.additional_fields
      if (additionalFields) {
        for (const additionalStepKey of Object.keys(additionalFields)) {
          const additionalStep = this.tableSettings.table_fields.additional_fields[additionalStepKey]
          for (const additionalFieldKey of Object.keys(additionalStep)) {
            fieldKeys.push(additionalFieldKey)
          }
        }
      }
      const displayedFields = fieldKeys.filter(
        (field) => this.tableSettings && !this.tableSettings.hidden_fields.includes(field)
      )
      displayedFields.unshift('index')
      _availableFields.unshift({ key: 'index', label: this.$gettext('#') })
      return _availableFields.filter((field) => {
        const fieldName = typeof field === 'string' ? field : field.key
        return displayedFields.includes(fieldName)
      })
    }
    return []
  }

  get availableFilterCols() {
    if (this.tableSettings) {
      const fieldKeys = Object.keys(this.tableSettings.table_fields)
      return this.filterCols.filter((filterCol) => {
        return (
          this.tableSettings &&
          !this.tableSettings.hidden_fields.includes(filterCol.field) &&
          fieldKeys.includes(filterCol.field)
        )
      })
    }
    return []
  }

  async loadOrganizations() {
    this.tableLoading = true
    await axios
      .get(
        addContextToUrl(API_URLS.ORGANIZATIONS.LIST, {
          sortBy: this.tableContext ? this.tableContext.sortBy : undefined,
          sortDesc: this.tableContext ? this.tableContext.sortDesc : undefined,
          search: this.searchString,
          filters: this.activeFilters,
          page: this.currentPage,
          pageSize: this.pageSize,
          archived: this.archived
        })
      )
      .then((response) => {
        this.organizations = response.data.results
        this.totalCount = response.data.count
        this.$emit('total-count-update', this.totalCount)
      })
    this.tableLoading = false
  }

  async reloadOrganizations(): Promise<void> {
    this.tableLoading = true
    this.currentPage = 1
    this.loadOrganizations()
  }

  async loadInternalStatus(): Promise<void> {
    return axios.get(API_URLS.ORGANIZATIONS.INTERNAL_STATUS).then((response) => {
      this.internalStatuses = response.data.results || []
      if (this.internalStatuses.length) {
        this.filters.internalStatusFilter.options = this.internalStatuses.reduce(
          (obj, item) => Object.assign(obj, { [item.title]: item.title }),
          {}
        )
      }
    })
  }

  async loadInitialOrganizations(): Promise<void> {
    this.$wait.start('fetch organizations')
    await Promise.all([this.loadOrganizations(), this.loadInternalStatus()])
    this.$wait.end('fetch organizations')
  }

  async created(): Promise<void> {
    this.loadInitialOrganizations()
  }
}
