

import { Component, Prop, Vue } from 'vue-property-decorator'
import axios from 'axios'
import SfAlert from '@/components/SfAlert.vue'
import { TGenericObject } from '@/types/base'

@Component({
  name: 'table-select-alert',
  components: {
    SfAlert
  }
})
export default class TableSelectAlert extends Vue {
  @Prop({ default: 'info' }) variant!: string
  @Prop() apiUrl: string
  @Prop() pageSize: number
  @Prop() items: TGenericObject[]
  @Prop() totalItemCount: number
  @Prop() selectedItems: TGenericObject[]
  loadingSelectTrueAll = false

  async selectTrueAll (): Promise<void> {
    this.loadingSelectTrueAll = true
    await axios.get(this.apiUrl).then(res => {
      this.$emit('change', res.data)
    })
    this.loadingSelectTrueAll = false
  }

  unselectTrueAll (): void {
    this.$emit('change', [])
    this.$emit('reset')
  }
}
