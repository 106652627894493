
import axios from 'axios'
import { Component, Prop, Vue } from 'vue-property-decorator'
import MapPlaceholder from '@/components/MapPlaceholder.vue'
import ProjectCardPlaceholder from '@/components/explore/ProjectCardPlaceholder.vue'
import { IPageSettings } from '@/types/cms'
import { IExploreProjectMinimal, ILocation, IProjectCategory, IProjectExploreFilters } from '@/types/projects'
import ShowcaseProjectCard from '../showcase_generator/ShowcaseProjectCard.vue'
import MapLoader from './MapLoader.vue'

@Component({
  components: {
    ProjectCardPlaceholder,
    MapPlaceholder,
    ShowcaseProjectCard,
    MapLoader
  }
})
export default class MapComponent extends Vue {
  @Prop({ default: 32 }) size!: number
  @Prop({ default: false }) listOnly
  @Prop() textBefore: string
  @Prop() projectLocations!: ILocation[]

  loading = false
  currentPage = 1
  projectCount = 1
  pageSize = 9
  categories: IProjectCategory[] = []
  projects: IExploreProjectMinimal[] = []
  showCategoryFilter = false
  showExpiredFilter = true

  projectFilters: IProjectExploreFilters = {
    searchString: '',
    hideFullyFunded: false,
    hideExpired: false,
    categories: [],
    sortOrder: null,
    cmsFilter: null,
    isShowcase: true
  }

  pageSettings: IPageSettings = null
  exploreHTML: string = null
  mapBoundsInitial = true
  googleMapsAccepted = false
  googleMapsApiKey = ""

  mapBounds = {
    lat: {
      min: null,
      max: null
    },
    lng: {
      min: null,
      max: null
    }
  }

  onBoundsChanged (bounds: google.maps.LatLngBounds | undefined): void {
    if (bounds) {
      const northEast = bounds.getNorthEast()
      const southWest = bounds.getSouthWest()
      this.mapBounds.lat.min = southWest.lat()
      this.mapBounds.lat.max = northEast.lat() < 0 ? 180 : northEast.lat()
      this.mapBounds.lng.min = southWest.lng()
      this.mapBounds.lng.max = northEast.lng() < 0 ? 180 : northEast.lng()
    }
  }

  get minMaxBounds (): google.maps.LatLngBoundsLiteral | undefined {
    if (this.projectLocations.length) {
      return {
        north: Math.max(...this.projectLocations.map(location => location.latitude)),
        east: Math.max(...this.projectLocations.map(location => location.longitude)),
        south: Math.min(...this.projectLocations.map(location => location.latitude)),
        west: Math.min(...this.projectLocations.map(location => location.longitude))
      }
    }
  }

  async created (): Promise<void> {
    this.$wait.start('load data')

    await axios.get("/api/v4/explore/settings/page/showcase/").then(response => {
      this.googleMapsApiKey = response.data.googleMapsApiKey
    })

    if (this.$cookies.get('cookie_rule_detail')?.includes('google-maps')) {
      this.googleMapsAccepted = true
    }

    if (document.getElementById('explore-html')) {
      this.exploreHTML = document.getElementById('explore-html').innerHTML
    }
  }
}
