
import { Component, Mixins, Prop } from 'vue-property-decorator'

import LocaleMixin from '@/mixins/LocaleMixin'
import { IPageSettings } from '@/types/cms'
import { IExploreProjectMinimal } from '@/types/projects'
import { TGenericObject } from '@/types/base'

@Component({
  name: 'project-card'
})
export default class ProjectCard extends Mixins(LocaleMixin) {
  @Prop() project!: IExploreProjectMinimal
  @Prop() pageSettings: IPageSettings
  @Prop() customBtnStyle: TGenericObject
  @Prop({ required: false }) affiliateParam: string

  getProjectPercentage (project: IExploreProjectMinimal): number {
    return Math.floor((project.balance / project.goal) * 100)
  }

  get isFoerderapp () {
    // todo: get from platform settings
    return true
  }

  get openLinkInNewTab (): boolean {
    /* eslint-disable object-shorthand */
    return this.$route.query?.widget === 'true' || this.$route.path.endsWith('/widget/')
  }

  get balance (): string {
    const { balance } = this.project

    return this.pageSettings.currencyDisplay.useCurrency
      ? this.toCurrency(balance, { currency: this.pageSettings.currencyDisplay.currencyIsoCode })
      : this.toCoins(balance, this.pageSettings.currencyDisplay.factorOfCurrencyToCoin)
  }

  truncate (title: string, length: number): string {
    if (title.length > length) {
      return `${title.slice(0, length - 3)} ...`
    }
    return title
  }

  openProject () {
    const params = { projectSlug: this.project.slug }
    if (this.affiliateParam) {
      const query = { showcase: this.affiliateParam }
      if (this.openLinkInNewTab) {
        window.open(this.$router.resolve({ name: 'project-detail', params: params, query: query }).href, '_blank')
        return
      }
      this.$router.push({ name: 'project-detail', params: params, query: query })
    } else {
      if (this.openLinkInNewTab) {
        window.open(this.$router.resolve({ name: 'project-detail', params: params }).href, '_blank')
        return
      }
      this.$router.push({ name: 'project-detail', params: params })
    }

    if (!this.pageSettings.singlePage) {
      location.reload()
    }
  }
}
