
import { Component, Prop, Vue } from 'vue-property-decorator'
import SocialMedia from '@/components/showcase_generator/social_media/SocialMedia.vue'
import { IShowcaseSocialMedia } from '@/types/showcase'
@Component({
  name: 'read-more-btn',
  components: {
    SocialMedia
  }
})
export default class ReadMoreBtn extends Vue {
  @Prop() readLessContent: string
  @Prop() customTabContent: string
  @Prop() readMoreBeforeClass: string
  @Prop({ default: 450 }) charLimit: number
  @Prop({ default: false }) showSocialMedia: boolean
  @Prop({ required: false }) socialMediaShareItems: IShowcaseSocialMedia
  @Prop({ required: false }) readMoreBtnTextColor: string | null

  expanded = false
  initialExpanded = false
  customTabContentBefore = null
  customTabContentAfter = null
  contentBefore = ""
  isCustomTab = false
  readMoreButtonType = {
    type: String,
    default: 'h1'
  }

  get customClass () {
    const classList = this.readMoreBeforeClass || ""
    return classList
  }

  get customClassBtn () {
    let classList = this.readMoreBeforeClass || ""
    if (this.expanded) {
      classList = classList + ' fas fa-angle-double-down'
    } else {
      classList = classList + ' fas fa-angle-double-right'
    }
    return classList
  }

  getContentBefore (): void {
    if (this.readLessContent) {
      this.contentBefore = this.readLessContent.substring(0, this.charLimit)
      this.isCustomTab = false
    } else {
      const parser = new DOMParser()
      const parsedHtml = parser.parseFromString(this.customTabContent, 'text/html')
      const beforeElement = parsedHtml.querySelector('#before')
      if (beforeElement) {
        this.customTabContentBefore = beforeElement.innerHTML
      }
      const afterElement = parsedHtml.querySelector('#after')
      if (afterElement) {
        this.customTabContentAfter = afterElement.innerHTML
      }
      this.isCustomTab = true
    }
  }

  mounted (): void {
    this.getContentBefore()
    this.expanded = this.readLessContent?.length <= this.charLimit
    this.initialExpanded = this.readLessContent?.length <= this.charLimit
  }
}
