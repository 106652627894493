import { render, staticRenderFns } from "./ProjectContentReviewStatus.vue?vue&type=template&id=4a0d247c&scoped=true&"
import script from "./ProjectContentReviewStatus.vue?vue&type=script&lang=ts&"
export * from "./ProjectContentReviewStatus.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4a0d247c",
  null
  
)

export default component.exports