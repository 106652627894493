
import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import { IContactPerson } from '@/types/foerderApp'
import ReadMoreCollapse from './ReadMoreCollapse.vue'
import VideoComponent from './VideoComponent.vue'
import SimpleSocialMediaArea from "@/components/detail_pages/SimpleSocialMediaArea.vue"
@Component({
  name: 'contact-person',
  components: {
    SimpleSocialMediaArea,
    ReadMoreCollapse,
    VideoComponent
  }
})
export default class ContactPerson extends Vue {
  @Prop({ required: true }) contactPerson: IContactPerson
  @Prop({ default: false }) resetImageDesc: boolean
  @Prop({ default: () => Vue.prototype.$gettext("Presented by") }) contactPersonTitle!: string
  @Prop({ default: '' }) logo: string
  showImageDesc = true

  @Watch("resetImageDesc")
  handleReset () {
    this.showImageDesc = true
  }

  get contactPersonPhoto () {
    if (this.contactPerson?.photos.length > 0) {
      return this.contactPerson?.photos[0]
    }
    if (this.contactPerson) {
      return {
        main_description: this.contactPerson.addressable_name,
        description: this.contactPerson.job_title,
        src: "/static/img/default/default_contact_person_img.png"
      }
    }
    return null
  }

  get hasContactDetails () {
    const contactFields = ["job_title", "phone", "mobile", "fax", "street", "public_contact_info"]
    return contactFields.some(field => this.contactPerson[field] !== "")
  }
}
