import { Component, Mixins, Prop } from 'vue-property-decorator'
import { IProjectDetailSettings, ICurrencyDisplaySettings, ICodeFormatSettings } from '@/types/cms'
import { TGenericObject } from '@/types/base'
import LocaleMixin from '@/mixins/LocaleMixin'
import UserMixin from '@/mixins/UserMixin'

@Component
export default class CodeInputAreaMixin extends Mixins(LocaleMixin, UserMixin) {
  @Prop({ default: null }) projectDetailSettings!: IProjectDetailSettings

  code = ''
  saving = false
  redemption: TGenericObject = null
  redemptionError: TGenericObject = null

  get currencyDisplay (): ICurrencyDisplaySettings {
    return this.projectDetailSettings?.currencyDisplay
  }

  get codeFormat (): ICodeFormatSettings {
    return this.projectDetailSettings?.codeFormat
  }

  get codeRegex (): RegExp {
    if (this.codeFormat) {
      const blockRegex = `[${this.codeFormat.chars}]{${this.codeFormat.blockLength}}`
      // Repeat the block n times, separated by up to 1 space
      const fullRegexString = `^(${blockRegex}\\s?){${(this.codeFormat.blockCount - 1)}}${blockRegex}$`
      // Return the compiled regex
      return new RegExp(fullRegexString)
    }
  }

  get codeFormatValid (): boolean {
    if (this.codeRegex) {
      return this.codeRegex.test(this.code)
    }
    return null
  }

  get strippedCodeLength (): number {
    return this.code.replace(/[^\w\d]/g, '').length
  }

  get codeInputValid (): boolean {
    if (this.codeFormatValid) return true
    if (this.errorMessage) return false
    return null
  }

  get errorMessage (): string {
    if (this.codeFormat) {
      const supposedCodeLength = this.codeFormat.blockCount * this.codeFormat.blockLength
      if ((this.strippedCodeLength === supposedCodeLength) && !this.codeFormatValid) {
        return this.$gettext('Invalid code format')
      } else if (this.strippedCodeLength > supposedCodeLength) {
        return this.$gettext('Code too long')
      }
    }
    return null
  }

  get redemptionErrorMessage (): string {
    if (this.redemptionError) {
      return this.redemptionError?.code_string.join(' - ')
    }
  }
}
