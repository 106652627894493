

import { Component, Ref, Mixins } from 'vue-property-decorator'
import axios from 'axios'
import decamelizeKeys from 'decamelize-keys'
import ToastMixin from '@/mixins/ToastMixin'
import SfSteps from '@/components/SfSteps.vue'
import BaseInformationStep from '@/components/documents/BaseInformationStep.vue'
import UploadFileStep from '@/components/documents/UploadFileStep.vue'
import MapContentStep from '@/components/documents/MapContentStep.vue'
import BreadcrumbHeader from '@/components/BreadCrumbHeader.vue'
import { IDocument, IDocumentBaseForm, IMappingField } from '@/types/documents'
import { IBreadcrumb, IStep, TGenericObject } from '@/types/base'
import { API_URLS } from '@/utils/helpers'

@Component({
  name: 'update-document',
  components: {
    BaseInformationStep,
    BreadcrumbHeader,
    UploadFileStep,
    MapContentStep,
    SfSteps
  }
})
export default class UpdateDocument extends Mixins(ToastMixin) {
  @Ref() readonly sfSteps!: SfSteps
  newDocumentFile: File = null
  document: IDocument = null
  documentForm: IDocumentBaseForm = {
    title: '',
    description: '',
    documentType: 32767 // Integer of custom document type
  }

  isSaving = false
  selectedMappingFields: TGenericObject = {}

  steps: IStep[] = [
    { value: 'base-information', text: this.$gettext('Base Information'), active: true, completed: true },
    { value: 'upload-file', text: this.$gettext('Upload File'), active: false, completed: false },
    { value: 'map-content', text: this.$gettext('Map Content'), active: false, completed: false }
  ]

  get breadcrumbs (): IBreadcrumb[] {
    return [
      {
        title: this.$gettext('Administrate Templates'),
        url: { name: 'list-documents' },
        routerUrl: true
      },
      {
        title: this.$gettext('Edit'),
        url: '#'
      }
    ]
  }

  get hasMappings (): boolean {
    return this.document.fields.length && this.document.fields.filter(field => field.mapping).length > 0
  }

  get isEditableDocumentType (): boolean {
    return [290, 390, 32767].includes(this.document ? this.document.document_type.choice : 0)
  }

  setFormDefault (): void {
    this.documentForm.title = this.document.title
    this.documentForm.description = this.document.description
    if (this.isEditableDocumentType) {
      this.documentForm.documentType = this.document.document_type.choice
    } else {
      delete this.documentForm.documentType
    }
  }

  loadDocument (): Promise<void> {
    return axios.get(API_URLS.DOCUMENTS.RETRIEVE(this.$route.params.documentSlug)).then(response => {
      this.document = response.data
      this.steps[1].completed = this.document.pdf_album && this.document.pdf_album.files.length > 0
      this.steps[2].completed = this.hasMappings
      this.setFormDefault()
    })
  }

  async updateDocument (): Promise<void> {
    this.isSaving = true
    await axios.patch(API_URLS.DOCUMENTS.PATCH(this.document.slug), decamelizeKeys(this.documentForm))
      .then(response => {
        this.document = response.data
        this.sfSteps.nextStep()
      })
      .catch(error => {
        throw error
      })
    this.isSaving = false
  }

  submitForm (event: Event): void {
    event.preventDefault()
    this.updateDocument()
  }

  async saveMapping (fields: IMappingField[]): Promise<void> {
    this.isSaving = true
    await axios.patch(API_URLS.DOCUMENTS.PATCH(this.document.slug), { fields })
      .catch(error => {
        throw error
      })
    this.isSaving = false
  }

  finishEdit (fields: IMappingField[]): void {
    this.saveMapping(fields).then(() => {
      this.$router.push(API_URLS.DOCUMENTS.ADMINISTRATION)
    })
  }

  async created (): Promise<void> {
    this.$wait.start('load')
    await this.loadDocument()
    this.$wait.end('load')
  }
}
