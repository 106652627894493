
import { Component, Prop, Vue } from 'vue-property-decorator'
import { BDropdown } from 'bootstrap-vue'
import { ISelectOption } from '@/types/base'

@Component({
  name: 'table-order-dropdown'
})
export default class TableOrderDropdown extends Vue {
  @Prop() label!: string
  @Prop({ default: () => [] }) options!: ISelectOption[]
  @Prop({ default: '' }) menuClass!: string
  $refs!: { dropdown: BDropdown }
  hasBeenApplied = false
  sortBy: string | number = ''
  sortDesc = false
  directionOptions = [
    { text: this.$gettext('Ascending'), value: false },
    { text: this.$gettext('Descending'), value: true }
  ]

  applyOrder (): void {
    this.hasBeenApplied = true
    this.$refs.dropdown.hide(true)
    this.$emit('order-apply', { sortBy: this.sortBy, sortDesc: this.sortDesc })
  }

  get menuClasses (): string {
    return `dropdown-menu-search ${this.menuClass}`
  }

  created (): void {
    if (this.options.length) this.sortBy = this.options[0].value
  }
}
