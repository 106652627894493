
import { Component, Mixins, Prop } from 'vue-property-decorator'

import EmailShareButton from '@/components/buttons/EmailShareButton.vue'
import FacebookShareButton from '@/components/buttons/FacebookShareButton.vue'
import TwitterShareButton from '@/components/buttons/TwitterShareButton.vue'
import WhatsappShareButton from '@/components/buttons/WhatsappShareButton.vue'
import { IProjectDetailSettings } from '@/types/cms'
import { IExploreProjectDetail } from '@/types/projects'
import { IExploreOrganizationDetail } from '@/types/organizations'
import MobileMixin from '@/mixins/MobileMixin'

@Component({
  name: 'social-media-area',
  components: {
    FacebookShareButton,
    WhatsappShareButton,
    TwitterShareButton,
    EmailShareButton
  }
})

export default class SocialMediaArea extends Mixins(MobileMixin) {
  @Prop() project!: IExploreProjectDetail | IExploreOrganizationDetail
  @Prop({ default: null }) projectDetailSettings: IProjectDetailSettings
}
