
import { ICustomTab } from '@/types/projects'
import { Component, Prop, Vue } from 'vue-property-decorator'

@Component({
  name: 'project-custom-tab'
})
export default class ProjectCustomTab extends Vue {
  @Prop() customTab!: ICustomTab
}
