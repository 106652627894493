
import { Component, Prop, Watch, Mixins } from 'vue-property-decorator'
import axios from 'axios'
import ToastMixin from '@/mixins/ToastMixin'
import UserMixin from '@/mixins/UserMixin'
import LocalisedBFormDatepicker from '@/components/LocalisedBFormDatepicker.vue'
import { ISelectOption, TGenericObject } from '@/types/base'
import { IUser } from '@/types/users'
import { IReminder, IReminderTemplate } from '../../types/reminders'
import { API_URLS } from '@/utils/helpers'
import { IOrganization, IProject } from '@/types/projects'
import { debounce } from 'lodash'

@Component({
  name: 'reminder-form',
  components: {
    LocalisedBFormDatepicker
  }
})
export default class ReminderForm extends Mixins(ToastMixin, UserMixin) {
  @Prop({ default: null }) project!: IProject | null
  @Prop({ default: null }) organization!: IOrganization | null
  @Prop({ default: false }) reminder!: IReminder | false
  @Prop({ default: null }) reminderTemplate!: IReminderTemplate | null

  userSearch = ''
  dueDate = ''
  users: IUser[] = []
  selectedAssignees: number[] = []
  title = ''
  description = ''
  errors: TGenericObject = {}
  sendCompletedMail = true
  saving = false

  @Watch('reminder')
  onReminderChange (): void {
    if (this.reminder) {
      this.title = this.reminder.title
      this.description = this.reminder.description
      this.dueDate = this.formatDate(new Date(this.reminder.scheduled_for))
      this.selectedAssignees = this.reminder.users.map(user => user.id)
      this.sendCompletedMail = this.reminder.send_completed_mail
    } else {
      this.resetForm()
    }
  }

  @Watch('reminderTemplate')
  onReminderTemplateChange (): void {
    if (!this.reminderTemplate) {
      this.title = ''
      this.description = ''
    } else {
      this.title = this.reminderTemplate.title
      this.description = this.reminderTemplate.description
    }
  }

  handleUserSearchChange (ev: Event): void {
    const target = ev.target as HTMLInputElement
    this.userSearch = target.value
  }

  formatDate (date: Date): string {
    const day = date.getDate() < 10 ? '0' + date.getDate() : date.getDate()
    const month = date.getMonth() < 9 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1
    return [day, month, date.getFullYear()].reverse().join('-')
  }

  resetForm (): void {
    this.title = ''
    this.description = ''
    this.dueDate = ''
    this.selectedAssignees = []
    this.sendCompletedMail = false
  }

  reminderPostError (error: TGenericObject) {
    if (error.response) {
      if (error.response.status === 403) {
        this.makeToast(
          'danger',
          this.$gettext('Not authorized'),
          this.$gettext('You do not have the permission to perfom this action')
        )
      } else {
        this.errors = error.response.data
      }
    } else {
      this.makeToast(
        'danger',
        this.$gettext('Error'),
        this.$gettext('Failed to create/update task.')
      )
    }
  }

  async createReminder () {
    this.saving = true
    await axios.post(API_URLS.REMINDERS.LIST, this.postData)
      .then(() => {
        this.$emit('refresh-reminders')
        this.resetForm()
        this.errors = {}
      })
      .catch(error => {
        this.reminderPostError(error)
      })
    this.saving = false
  }

  async updateReminder (): Promise<void> {
    if (!this.reminder) return
    this.saving = true
    await axios.patch(API_URLS.REMINDERS.RETRIEVE(this.reminder.slug), this.postData)
      .then(() => {
        this.$emit('refresh-reminders')
        this.errors = {}
      })
      .catch(error => {
        this.reminderPostError(error)
      })
    this.saving = false
  }

  get assigneButtonLabel (): string {
    if (this.selectedAssignees.length) {
      return this.selectedAssignees.length + ' ' + this.$gettext('selected')
    }
    return this.$gettext('Choose')
  }

  get filteredUsers (): IUser[] {
    const lowerCaseUserSearch = this.userSearch.toLowerCase()
    return this.users.filter(user => (
      user.username.toLowerCase().includes(lowerCaseUserSearch) ||
      (user.full_name_w_username && user.full_name_w_username.toLowerCase().includes(lowerCaseUserSearch)) ||
      user.email.toLowerCase().includes(lowerCaseUserSearch)
    ))
  }

  get assigneeOptions (): ISelectOption[] {
    return this.filteredUsers.map(user => {
      return {
        value: user.id,
        text: user.full_name_w_username
      }
    })
  }

  get postData (): TGenericObject {
    return {
      title: this.title,
      description: this.description,
      scheduled_for: this.dueDate,
      targetId: this.project ? this.project.id : this.organization.id,
      targetType: this.project ? 'project' : 'organization',
      users: this.selectedAssignees,
      send_completed_mail: this.sendCompletedMail
    }
  }

  getErrorText (key: string): string {
    if (key in this.errors) {
      return this.errors[key].map(text => { return text.toString() }).join(', ')
    } else {
      return ""
    }
  }

  errorState (key: string): boolean {
    return !(key in this.errors)
  }

  async fetchStaffUsers (): Promise<void> {
    const staffUsers = await axios.get(API_URLS.USERS.STAFF_LIST).then(response => {
      return response.data
    }).catch(error => {
      throw new Error(error.message)
    })
    // .then(res => {
    //   if (!res.ok) { throw new Error(res.statusText) }
    //   return res.json()
    // })
    this.users = staffUsers
  }

  async created (): Promise<void> {
    this.fetchStaffUsers()
    this.fetchUserProfile()
    this.handleUserSearchChange = debounce(this.handleUserSearchChange, 500)
  }
}
