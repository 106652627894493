
import { Component, Vue } from 'vue-property-decorator'
import debounce from 'lodash/debounce'

@Component
export default class MobileMixin extends Vue {
  windowWidth = 0
  windowHeight = 0

  get isTouchDevice (): boolean {
    let hasTouchScreen = false
    if ("maxTouchPoints" in navigator) {
      hasTouchScreen = navigator.maxTouchPoints > 0
    } else {
      const mQ = window.matchMedia && matchMedia("(pointer:coarse)")
      if (mQ && mQ.media === "(pointer:coarse)") {
        hasTouchScreen = !!mQ.matches
      } else if ('orientation' in window) {
        hasTouchScreen = true // deprecated, but good fallback
      } else {
        // Only as a last resort, fall back to user agent sniffing
        const UA = navigator.userAgent
        hasTouchScreen = (
          /\b(BlackBerry|webOS|iPhone|IEMobile)\b/i.test(UA) ||
          /\b(Android|Windows Phone|iPad|iPod)\b/i.test(UA)
        )
      }
    }
    return hasTouchScreen
  }

  get isMobile () {
    return this.windowWidth && this.windowWidth < 768
  }

  get isMobilePortrait () {
    return this.windowWidth && this.windowHeight && this.isMobile && this.windowWidth < this.windowHeight
  }

  get isMobileLandscape () {
    return this.isMobile && !this.isMobilePortrait
  }

  get isTablet () {
    return this.windowWidth && !this.isMobile && this.windowWidth < 992
  }

  get isDesktop () {
    return this.windowWidth && this.windowWidth >= 992
  }

  onResize () {
    this.windowHeight = window.innerHeight
    this.windowWidth = window.innerWidth
  }

  created () {
    this.onResize()
    this.onResize = debounce(this.onResize, 200)
    window.addEventListener('resize', this.onResize)
  }
}
