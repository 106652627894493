
import { Component, Vue, Prop, Ref } from 'vue-property-decorator'
import QrcodeVue from 'qrcode.vue'

@Component({
  components: {
    QrcodeVue
  }
})
export default class QrCodeButton extends Vue {
  @Prop() readonly url: string
  @Prop({ default: false }) disabled
  @Ref() readonly qr_code_div!: Vue
  @Prop({ default: "qr_code" }) fileName

  get tooltip (): string {
    return this.disabled ? this.$gettext('Your page is not public') : this.$gettext('Download qr code')
  }

  downloadQRCode (): void {
    const qrcodeComponent = this.$refs.qr_code_div as QrcodeVue
    const canvasElement = qrcodeComponent.$el.querySelector("canvas")
    const dataUrl = canvasElement.toDataURL('image/png')
    const link = document.createElement('a')
    link.href = dataUrl
    link.download = this.fileName.replace(/[^a-zA-Z0-9_]/g, '').slice(0, 20).toLowerCase()
    link.click()
  }
}
