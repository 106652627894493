
import { BAlert } from 'bootstrap-vue'
import { Component, Mixins, Prop } from 'vue-property-decorator'

@Component({
  name: 'sf-alert'
})
export default class SfAlert extends Mixins(BAlert) {
  @Prop({ default: 'info' }) variant!: string
  @Prop({ default: 'info-circle' }) icon!: string
  @Prop({ default: 'fa' }) iconWeight!: string
}
