

import { AlbumInfo } from '@/types/photos'
import { API_URLS } from '@/utils/helpers'
import axios from 'axios'
import { Vue, Component, Prop } from 'vue-property-decorator'
@Component
export default class InfoBox extends Vue {
  @Prop({ required: false }) albumSlug: string
  @Prop({ default: null }) showUploadRightsInfo: boolean

  albumInfo: AlbumInfo = null

  get showUploadRightsHint (): boolean {
    if (this.showUploadRightsInfo === null) {
      return this.albumInfo?.upload_rights_hint
    } else {
      return this.showUploadRightsInfo
    }
  }

  mounted (): void {
    if (this.albumSlug) {
      axios.get(API_URLS.ALBUM.INFO(this.albumSlug)).then((response) => {
        this.albumInfo = response.data
        this.$emit('album-info-fetched', this.albumInfo)
      })
    }
  }
}
