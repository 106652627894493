
import { Vue, Component, Prop } from 'vue-property-decorator'

@Component
export default class InfoBanner extends Vue {
  @Prop({ type: String, default: 'info' }) type!: 'info' | 'success' | 'error'
  @Prop({ type: Boolean, default: false }) show!: boolean

  get bannerClass () {
    return 'ps-info-banner ' + this.type
  }
}
