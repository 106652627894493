
import { TGenericObject } from '@/types/base'
import { IPrivateDonationForm } from '@/types/projects'
import { Component, Prop, Vue } from 'vue-property-decorator'
import EmailInput from './EmailInput.vue'
import AddressInput from './AddressInput.vue'
import { IProjectDetailSettings } from '@/types/cms'
import DonationEmailInfoCheckbox from './hds/DonationEmailInfoCheckbox.vue'
@Component({
  name: 'private-donation-form',
  components: {
    EmailInput,
    AddressInput,
    DonationEmailInfoCheckbox
  }
})
export default class PrivateDonationForm extends Vue {
  @Prop({ required: false }) form: IPrivateDonationForm
  @Prop() formErrors!: TGenericObject
  @Prop() projectDetailSettings!: IProjectDetailSettings

  terms_of_address = [
    {
      text: this.$gettext("Term of address"),
      value: 1
    },
    {
      text: this.$gettext("Mrs."),
      value: 2
    },
    {
      text: this.$gettext("Mr."),
      value: 3
    }
  ]

  academic_titles = [
    {
      text: this.$gettext('Academic title'),
      value: 1
    },
    {
      text: this.$gettext('Doctor'),
      value: 2
    },
    {
      text: this.$gettext('Professor'),
      value: 3
    }
  ]

  get extendedAddressInformation () {
    return this.projectDetailSettings.enableFoerderApp
  }

  get privateDonationForm () {
    return this.form
  }

  set privateDonationForm (value: IPrivateDonationForm) {
    this.$emit("input", value)
  }

  errorState (field: string): boolean {
    return !this.formErrors[field]
  }

  changeAnoymusInInfoMail (value: boolean) {
    this.privateDonationForm.anonymus_in_info_mail = value
  }
}
