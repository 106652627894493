
import { Component, Mixins, Prop, Watch } from 'vue-property-decorator'
import UserMixin from '@/mixins/UserMixin'
import CodeStringInput from '@/components/funding_code/shared/CodeStringInput.vue'
import GenericTable from '@/components/GenericTable.vue'
import { BvTableFieldArrayWithStickColumn, TGenericObject } from '@/types/base'
import { BvTableCtxObject } from "bootstrap-vue"
import { isArray } from 'lodash'
import DeleteButton from '@/components/buttons/DeleteButton.vue'
import AddButton from '@/components/buttons/AddButton.vue'
import AbortButton from '@/components/buttons/AbortBtn.vue'
import RemoveButton from '@/components/buttons/RemoveButton.vue'
@Component({
  components: {
    CodeStringInput,
    GenericTable,
    DeleteButton,
    AddButton,
    AbortButton,
    RemoveButton
  }
})
export default class SelectTable extends Mixins(UserMixin) {
  @Prop({ required: true }) apiUrl: string
  @Prop({ required: false }) apiUrlExtraParamsForSelected: TGenericObject
  @Prop({ required: false }) apiUrlExtraParamsForNotSelected: TGenericObject
  @Prop() initialArraySelected: TGenericObject[]
  @Prop() fields!: BvTableFieldArrayWithStickColumn
  @Prop({ required: false }) removeTableFields: BvTableFieldArrayWithStickColumn
  @Prop() itemName: string
  @Prop({ default: false }) removeOnly: string
  @Prop() itemNamePlural: string
  @Prop({ default: false }) showInfoIcon
  @Prop({ default: false }) noDesc
  @Prop({ default: false }) showAddSelectedBtn
  @Prop({ default: true }) deleteItemBtn
  @Prop({ default: true }) showActionColInRemoveTable
  $refs!: { addTable: GenericTable, removeTable: GenericTable }
  pageSize = 10
  selectedItems: TGenericObject[] = []
  selectedItemsSelectTable: TGenericObject[] = []
  selectedItemsRemoveTable: TGenericObject[] = []
  tableLoading = true
  tableContext: BvTableCtxObject | null = null
  showInfoModal = false
  showRemoveTable = false
  showAddTable = false

  @Watch('initialArraySelected')
  handleSelectionChanged () {
    this.$refs.addTable?.loadItems()
    this.$refs.removeTable?.loadItems()
    this.showRemoveTable = this.initialArraySelected?.length > 0
  }

  mounted () {
    this.showRemoveTable = this.initialArraySelected?.length > 0
  }

  get fieldsForRemoveTable (): BvTableFieldArrayWithStickColumn {
    if (!this.removeTableFields) {
      return this.fields
    }
    return this.removeTableFields
  }

  get itemSelectedCount () {
    return this.initialArraySelected?.length || 0
  }

  get itemNameSg () {
    if (!this.itemName) {
      return this.$gettext("item")
    }
    return this.itemName
  }

  get itemNamePl () {
    if (!this.itemNamePlural) {
      return this.$gettext("items")
    }
    return this.itemNamePlural
  }

  sortingChanged (ctx: BvTableCtxObject): void {
    this.tableContext = ctx
  }

  onRowSelectedSelectTable (selectedItems: TGenericObject): void {
    const selected = isArray(selectedItems) ? selectedItems : [selectedItems]
    this.selectedItemsSelectTable = selected
    this.$emit("row-selected", this.selectedItems)
  }

  onRowSelectedRemoveTable (selectedItems: TGenericObject): void {
    const selected = isArray(selectedItems) ? selectedItems : [selectedItems]
    this.selectedItemsRemoveTable = selected
    this.$emit("row-selected", this.selectedItems)
  }

  get userIsOrgaUser () {
    return this.userProfile?.usertype?.choice === 4
  }

  removeItem (project) {
    this.$emit("items-removed", [project])
  }

  removeAllItems () {
    this.$emit("items-removed", this.initialArraySelected)
  }

  removeSelectedItems () {
    this.$emit("items-removed", this.selectedItemsRemoveTable)
  }

  addSelectedItems () {
    this.$emit("items-selected", this.selectedItemsSelectTable)
  }

  addSingleItem (dataItem: null | TGenericObject) {
    const selected = isArray(dataItem) ? dataItem : [dataItem]
    this.selectedItemsSelectTable = selected
    this.$emit("row-selected", this.selectedItems)
    this.$emit("items-selected", this.selectedItemsSelectTable)
  }
}
