
import { Component, Vue, Prop } from 'vue-property-decorator'
import FacebookButton from './FacebookButton.vue'
import XButton from './XButton.vue'
import WhatsappButton from './WhatsappButton.vue'
import EmailButton from './EmailButton.vue'
import { IShowcaseSocialMedia } from '@/types/showcase'
import { isArray } from 'lodash'
import CopyURLButton from '@/components/buttons/CopyURLButton.vue'

@Component({
  components: {
    FacebookButton,
    XButton,
    WhatsappButton,
    EmailButton,
    CopyURLButton
  }
})
export default class SocialMedia extends Vue {
  @Prop({ required: false }) shareItems: IShowcaseSocialMedia
  @Prop({ default: false }) noCopyBtn: boolean

  // Todo: Which texts and tags should be displayed?
  get hashtags () {
    const hashtags = this.shareItems.twitter_default_hashtags
    return isArray(hashtags) ? hashtags : [hashtags]
  }
}
