
import { Component, Prop, Vue, Emit, Watch } from 'vue-property-decorator'
@Component
export default class TextField extends Vue {
  @Prop({ default: '' }) value!: string
  @Prop({ default: '' }) error!: string
  @Prop({ default: null }) label!: string
  @Prop({ default: false }) required!: boolean
  @Prop({ default: false }) readOnly !: string
  @Prop({ default: '' }) placeholder !: string
  // add prop for minLength default null - if exists, validate - show state and field error, show current length and remaining
  // add prop for maxLength
  state: boolean | null = null

  @Emit('input')
  updateValue(newValue: InputEvent) {
    const input = newValue.target as HTMLInputElement
    return input.value
  }

  @Watch('error')
  onPropertyChanged(value: string) {
    this.state = value ? false : null
  }
}
