

import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import AbortButton from './AbortBtn.vue'

@Component({
  components: {
    AbortButton
  }
})
export default class EditButton extends Vue {
  @Prop({ required: false }) href!: string
  @Prop({ required: false }) route!: string
  @Prop({ default: () => Vue.prototype.$gettext('Edit') }) tooltip!: string
  @Prop({ default: false }) disabled
  @Prop({ default: false }) useAbortBtn
  @Prop({ default: false }) reset

  actionStarted = false

  @Watch("reset")
  onResetChanged (value) {
    this.actionStarted = !value
  }

  get showAbortBtn () {
    return this.useAbortBtn && this.actionStarted
  }

  get showEditBtn () {
    if (!this.useAbortBtn) { return true }
    return !this.actionStarted
  }

  onButtonClicked (): void {
    this.actionStarted = true
    this.$emit("btn-clicked") // todo remove at all usages and use clicked instead
    this.$emit("clicked")
  }

  onActionAborted (): void {
    this.actionStarted = false
    this.$emit("action-aborted")
  }
}
