
import { Component, Prop, Vue, Emit } from 'vue-property-decorator'

@Component
export default class CopyToClipboard extends Vue {
  @Prop({ required: true }) text!: string
  @Prop({ default: 'Kopieren' }) buttonText!: string

  showCopied = false

  @Emit('copied')
  async copyToClipboard () {
    await navigator.clipboard.writeText(this.text)
    this.showCopied = true
    setTimeout(() => {
      this.showCopied = false
    }, 4000)
  }
}
