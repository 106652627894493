
import { Component, Vue } from 'vue-property-decorator'
@Component
export default class FieldValidationMixin extends Vue {
  displayError = ''

  isValidEmail (email: string): boolean {
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/
    const value = emailRegex.test(email)
    return value
  }

  requiredFieldIsEmpty (value: string, required: boolean): boolean {
    if (required && value.length === 0) {
      this.displayError = 'This field is required'
      return true
    }
    this.displayError = ''
    return false
  }
}
