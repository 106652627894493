
import { Component, Vue } from 'vue-property-decorator'
import IconButton from '@/components/buttons/IconButton.vue'
import axios from 'axios'
import { API_URLS } from '@/utils/helpers'

// todo: which texts and hashtags are we supposed to show for:
// Project detail page, result report page, showcase (funding proposal and result report)
// todo: Replace other SocialMediaArea with this one
@Component({
  name: 'SimpleSocialMediaArea',
  components: {
    IconButton
  }
})
export default class SimpleSocialMediaArea extends Vue {
  facebookHashtags = []

  async mounted (): Promise<void> {
    await this.fetchShareData()
  }

  async fetchShareData (): Promise<void> {
    const projectSettings = await axios.get(API_URLS.EXPLORE.SETTINGS.PROJECT).then(response => {
      return response.data
    })
    this.facebookHashtags = projectSettings.share.facebook.hashtags
  }

  get facebookShareUrl (): string {
    return (
      'https://www.facebook.com/sharer/sharer.php?display=popup' +
      `&u=${location.protocol + '//' + location.host + location.pathname}` +
      `&hashtag=${this.facebookHashtags.map(hashtag => '%23' + hashtag).join(',')}`
    )
  }

  openFacebookPopup (): void {
    window.open(this.facebookShareUrl, 'pop', 'width=600, height=400, scrollbars=no')
  }
}
