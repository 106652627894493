
import { Component, Mixins, Prop } from 'vue-property-decorator'
import axios from 'axios'
import ToastMixin from '@/mixins/ToastMixin'

@Component({
  name: 'export-modal'
})
export default class ExportModal extends Mixins(ToastMixin) {
  @Prop({ default: false }) value!: boolean
  @Prop({ default: 0 }) itemCount!: number
  @Prop({ default: 500 }) directExportLimit!: number
  @Prop({ default: 'export.xlsx' }) fileName!: string
  @Prop() directExportUrl: string
  @Prop() asyncExportUrl: string

  startingDirectExport = false
  startingAsyncExport = false

  get allowDirectExport (): boolean {
    return this.itemCount < this.directExportLimit
  }

  get exportStarted (): boolean {
    return this.startingDirectExport || this.startingAsyncExport
  }

  get isOpenModel (): boolean {
    return this.value
  }

  set isOpenModel (value: boolean) {
    this.$emit('input', value)
  }

  async startDirectExport (): Promise<void> {
    this.startingDirectExport = true
    await axios.get(this.directExportUrl, {
      responseType: 'blob'
    }).then(response => {
      const url = window.URL.createObjectURL(
        new Blob([response.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' })
      )
      const link = document.createElement('a')
      link.href = url
      link.setAttribute('download', this.fileName)
      document.body.appendChild(link)
      link.click()
    })
    this.startingDirectExport = false
  }

  async startAsyncExport (): Promise<void> {
    this.startingAsyncExport = true
    await axios.get(this.asyncExportUrl).then(response => {
      this.makeToast(
        'success',
        response.data.message,
        this.$gettext('You will receive an e-mail once your export is ready!')
      )
    }).catch(error => {
      if (error.response && error.response.status) {
        this.makeToast(
          'danger',
          this.$gettext('Error'),
          this.$gettext('Failed to start export')
        )
      }
    })
    this.startingAsyncExport = false
  }
}
