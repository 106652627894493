
import { Component, Prop, Vue } from 'vue-property-decorator'

@Component({
  name: 'twitter-share-button'
})
export default class XButton extends Vue {
  @Prop({ default: 'transparent' }) variant: string
  @Prop({ default: 'smb-fb-foerder-app' }) customClass: string
  @Prop() hashtags: string[]
  @Prop({ default: '' }) shareText: string

  get shareUrl (): string {
    return (
      'https://twitter.com/intent/tweet?' +
      `url=${location.protocol + '//' + location.host + location.pathname}` +
      `&text=${this.shareText}` +
      `&hashtags=${this.hashtags.join(',')}`
    )
  }

  openPopup (): void {
    window.open(this.shareUrl, 'sharer', 'toolbar=0, status=0, width=520, height=350')
  }
}
