
import { Component, Vue, Prop } from 'vue-property-decorator'
@Component
export default class PasswordFields extends Vue {
  @Prop() password!: string
  @Prop() password_repeat!: string
  @Prop() formError!: string
  @Prop() passwordState: 'weak' | 'medium' | 'strong' | 'notMatching' | null

  passwordInput (): void {
    if (this.password_repeat) {
      this.$emit('password-input')
    }
  }
}
