
import { Component, Prop, Vue } from 'vue-property-decorator'

@Component({
  name: 'voting-info'
})
export default class VotingInfo extends Vue {
  @Prop() currentRank: number
  @Prop() totalRank: number
  @Prop() receivedVotes: number
}
