
import { Component, Vue, Prop } from 'vue-property-decorator'

@Component
export default class NavigationIcon extends Vue {
  @Prop({ default: false }) completed!: boolean
  @Prop() title!: string
  @Prop() stepNr!: string

  clicked (): void {
    this.$emit('clicked')
  }
}
