
import { Component, Prop, Mixins } from 'vue-property-decorator'
import { IMinimalProject } from '@/types/projects'
import ToastMixin from '@/mixins/ToastMixin'
import { API_URLS } from '@/utils/helpers'
import axios from 'axios'

@Component({
  name: 'DuplicateProjectModal'
})
export default class DuplicateProjectModal extends Mixins(ToastMixin) {
  @Prop({ default: false }) value!: boolean
  @Prop({ default: () => [] }) projects!: IMinimalProject[]
  @Prop({ default: false }) project!: IMinimalProject | false // Because of double functionality to use the modal from single project options menu
  isRequestingAPI = false

  get isOpenModel (): boolean {
    return this.value
  }

  set isOpenModel (value: boolean) {
    this.$emit('input', value)
  }

  async confirmDuplication () {
    if (!this.projects && !this.project) return
    this.isRequestingAPI = true
    let slugList: string[] = []
    if (this.project) {
      slugList = [this.project.slug]
    } else {
      for (const proj of this.projects) {
        slugList.push(proj.slug)
      }
    }
    await axios.post(API_URLS.PROJECTS.DUPLICATE, {
      project_slugs: slugList
    }).then(() => {
      this.makeToast(
        'success',
        this.$gettext('Success'),
        this.$gettext('Successfully duplicated')
      )
      this.$emit('close')
      this.$emit('success')
    }).catch(error => {
      this.makeToast(
        'danger',
        this.$gettext('Defunding failed!'),
        ''
      )
      throw Error(error.response)
    })
    this.isRequestingAPI = false
  }
}
