
import { Component, Prop, Watch, Mixins } from 'vue-property-decorator'
import axios from 'axios'
import FormModule from '@/components/FormModule.vue'
import SfAlert from '@/components/SfAlert.vue'
import { API_URLS } from '@/utils/helpers'
import ToastMixin from '@/mixins/ToastMixin'
import { IDocument } from '@/types/documents'

@Component({
  name: 'upload-file-step',
  components: {
    FormModule,
    SfAlert
  }
})
export default class UploadFileStep extends Mixins(ToastMixin) {
  @Prop({ default: null }) value!: File
  @Prop({ default: '' }) document!: IDocument
  albumContentHTML = ''
  newFile: File = null
  dropActive = false
  isUploading = false

  @Watch('newFile')
  onFileChange (): void {
    this.$emit('input', this.newFile)
    if (this.newFile) this.uploadFile()
  }

  formatDate (dateStr: string) {
    return this.$moment(dateStr).format('LLL')
  }

  async uploadFile (): Promise<void> {
    if (this.newFile.type === 'application/pdf') {
      const formData = new FormData()
      formData.append('file', this.newFile)
      this.isUploading = true
      await axios.put(
        API_URLS.DOCUMENTS.UPLOAD_PDF(this.document.slug),
        formData,
        { headers: {
          'Content-Type': 'multipart/form-data',
          'Content-Disposition': 'attachment; filename=' + this.newFile.name
        } }
      ).then(() => {
        this.$emit('file-uploaded')
      }).catch(error => {
        // There will be an error if the filename contains non-ascii chars before a request is sent.
        if ('response' in error && error.response.status === 403) {
          this.makeToast('danger', this.$gettext('Not allowed'), error.response.data.error)
        } else {
          if ('response' in error && error.response.data && error.response.data.error) {
            this.makeToast('danger', this.$gettext('Error'), error.response.data.error)
          } else {
            this.makeToast('danger', this.$gettext('Error'), `${this.$gettext('Failed to upload file')}\n\n${error}`)
          }
        }
      })
      this.isUploading = false
    } else {
      this.makeToast('danger', this.$gettext('Invalid file type'), this.$gettext('Only PDF files are allowed to be uploaded'))
    }
  }

  deleteFile (): void {
    axios.delete(API_URLS.DOCUMENTS.DELETE_PDFS(this.document.slug)
    ).then(() => {
      this.$emit('file-deleted')
    }).catch(() => {
      this.makeToast('danger', this.$gettext('Error'), this.$gettext('Failed to delete file'))
    })
  }
}
